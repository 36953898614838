import React, { useState } from 'react';
import './ExportClients.css';
import ReportingService from '../../../../AppSettings/services/ReportingService';

const columnOptions = [
    'Client ID', 'First Name', 'Last Name', 'Company', 'Email', 'Phone',
    'Address', 'Type'
];

const ExportClients = () => {
    const [selectedColumns, setSelectedColumns] = useState(columnOptions);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleColumnChange = (column) => {
        const newSelection = selectedColumns.includes(column)
            ? selectedColumns.filter(c => c !== column)
            : [...selectedColumns, column];
        setSelectedColumns(newSelection);
    };

    const toggleAllColumns = () => {
        if (selectedColumns.length === columnOptions.length) {
            setSelectedColumns([]);
        } else {
            setSelectedColumns(columnOptions);
        }
    };

    const downloadReport = async () => {
        try {
            const response = await ReportingService.getAllClientsExcel(selectedColumns);

            if (response.status !== 200) {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = "Clients_Report.xlsx";
            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(downloadUrl);
                a.remove();
            }, 100);
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    const closeModal = () => setIsModalOpen(false);

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('reporting-overlay')) {
            closeModal();
        }
    };

    return (
        isModalOpen && (
            <div className="reporting-overlay" onClick={handleOverlayClick}>
                <div className="reporting-modal-content">
                    <div className="reporting-button-container">
                        <button onClick={downloadReport} className="Reporting-Download-button">Download Report</button>
                        <button onClick={toggleAllColumns} className="Reporting-Export-button">
                            {selectedColumns.length === columnOptions.length ? 'Deselect All' : 'Select All'}
                        </button>
                    </div>
                    {columnOptions.map((column, idx) => (
                        <label key={idx} className="reporting-checkbox">
                            <input
                                type="checkbox"
                                checked={selectedColumns.includes(column)}
                                onChange={() => handleColumnChange(column)}
                                className="reporting-visually-hidden"
                            />
                            <span className="reporting-label">{column}</span>
                        </label>
                    ))}
                </div>
            </div>
        )
    );
};

export default ExportClients;
