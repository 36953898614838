import { useState } from "react";
import "./FinancialsPage.css";
import FinancialDashboard from "./FinancialDashboard/FinancialDashboard";
import FixedFees from "./FixedFees/GeneralFixedFees";
import DisbursmentsPage from "./Disbursements/DisbursementPage";
import BillableTime from "./BillableTime/BillableTime";
import FinancialsDetailsTopBar from "./Components/TopBar/TopBar";

const FinancialsPage = () => {
  const [activePage, setActivePage] = useState("dashboard"); // State to track the active section

  const renderActiveSection = () => {
    switch (activePage) {
      case "dashboard":
        return <FinancialDashboard />;
      case "fixedFees":
        return <FixedFees />;
      case "disbursements":
        return <DisbursmentsPage />;
      case "billableTime":
        return <BillableTime />;
      case "clients":
        return <></>;
      case "agents":
        return <></>;
      case "invoices":
        return <></>;
      default:
        return <FinancialDashboard />;
    }
  };

  return (
    <div className="financial-details-page">
      <FinancialsDetailsTopBar setActivePage={setActivePage} /> {/* Pass setActivePage */}

      <div>
        {renderActiveSection()}
       </div> {/* Render the selected section */}
    </div>
  );
};

export default FinancialsPage;
