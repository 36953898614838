import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "./Components/IconButton";
import "./TopBar.css";
import { faUser, faUserFriends, faPlus, faUpload, faBars, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams, useNavigate } from 'react-router-dom';
import SearchBar from "./Components/SearchBar";
import MatterService from "../../../../../../AppSettings/services/MatterService";

const TopBar = ({ onSearch, onToggleTasks }) => {
  const { matterId } = useParams();
  const navigate = useNavigate();
  const [matterName, setMatterName] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Track menu visibility
  const menuRef = useRef(null); // Ref to track the menu container

  const toggleMenu = () => setMenuOpen((prev) => !prev); // Toggle menu visibility

  useEffect(() => {
    fetchMatterName();
  }, [matterId]);

  const fetchMatterName = async () => {
    const response = await MatterService.getMatterName(matterId);
    setMatterName(response);
  };

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="topBar">
      <div id="leftSide">
      <Link to={`/matterDetails/${matterId}`}>
          <button className="MatterDetailsBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="MatterDetailsBackButton" />
          </button>
        </Link>
        <span>{matterId} {matterName}</span>
        <IconButton
          icon={faUser}
          ariaLabel="User"
          onClick={() => onToggleTasks(true)} // Show "My Tasks"
        />
        <IconButton
          icon={faUserFriends}
          ariaLabel="Group"
          onClick={() => onToggleTasks(false)} // Show "All Tasks"
        />

      </div>
      <div id="rightSide">
        <IconButton icon={faUpload} ariaLabel="Upload" />
        <SearchBar onSearch={onSearch} />
        <IconButton icon={faBars} ariaLabel="Bars" onClick={toggleMenu} />
          {menuOpen && (
            <div className="Matter-dropdown-menu-mattertasks">
              <button onClick={() => navigate(`/matterDetails/${matterId}`)}>General</button>
              <button onClick={() => navigate(`/matterDetails/:matterId/tasks`)}>Tasks</button>
              <button onClick={() => navigate(`/matterDetails/${matterId}/financial`)}>Financial</button>
              <button onClick={() => navigate(`/matterDetails/${matterId}/files`)}>Files</button>
            </div>
          )}
      </div>
    </div>
  );
};

export default TopBar;
