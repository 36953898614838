import React, { useState } from 'react';
import './MatterDetails.css';
import GeneralMatters from './GeneralMatters/GeneralMatters';
import FinancialPage from './Financial/FinancialPage';
import Files from './Files/Files';
import TopBar from './Components/TopBar'; // Ensure the correct path

const MatterDetails = () => {
  // Set 'general' as the default active page
  const [activePage, setActivePage] = useState('general');

  const renderActivePage = () => {
    switch (activePage) {
      case 'general':
        return <GeneralMatters />;
      case 'financial':
        return <FinancialPage />;
      case 'files':
        return <Files />;
      default:
        return <GeneralMatters />; // Fallback to GeneralMatters
    }
  };

  return (
    <div className="matter-details-page">
      {/* Pass setActivePage function to the top bar */}
      <TopBar setActivePage={setActivePage} />
      {/* Render the selected section */}
      <div className="matter-content">{renderActivePage()}</div>
    </div>
  );
};

export default MatterDetails;

