import React from 'react';
import './InvoiceWidget.css';  // Make sure you import the CSS file

function InvoiceWidget({invoiceData}) {

    return (
        <div className="invoice-widget">
            <h1 className="invoice-widget-title">Invoices</h1>
            <div className="invoice-status">
                <div className="invoice pending">
                    <div className="invoice-status-bar invoice-pending-bar"></div>
                    <div className="invoice-data">
                        <h2 className="invoice-data-title">Sent</h2>
                        <p className="invoice-data-input">{invoiceData.sent}</p>
                        <div className="invoice-lines-container">
                            {Array.from({ length: 23 }).map((_, index) => (
                                <div key={index} className="invoice-line invoice-pending-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="invoice in-progress">
                    <div className="invoice-status-bar invoice-in-progress-bar"></div>
                    <div className="invoice-data">
                        <h2 className="invoice-data-title">Unpaid</h2>
                        <p className="invoice-data-input">{invoiceData.unpaid}</p>
                        <div className="invoice-lines-container">
                            {Array.from({ length: 23}).map((_, index) => (
                                <div key={index} className="invoice-line invoice-in-progress-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="invoice completed">
                    <div className="invoice-status-bar invoice-completed-bar"></div>
                    <div className="invoice-data">
                        <h2 className="invoice-data-title">Paid</h2>
                        <p className="invoice-data-input">{invoiceData.paid}</p>
                        <div className="invoice-lines-container">
                            {Array.from({ length: 23}).map((_, index) => (
                                <div key={index} className="invoice-line invoice-completed-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceWidget;
