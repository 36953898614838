import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class DeadlineChecklistService {
  
    // Deadline Fetches

    static async getAllDeadlines() {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/deadlines`, { headers });
            return response.data;
        } catch (error) {
            console.error('Error fetching all deadlines:', error);
            throw error;
        }
    }

    static async getDeadlinesByMatterId(matterId) {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/deadlines/${matterId}`, { headers });
            return response.data;
        } catch (error) {
            console.error(`Error fetching deadlines for matter ID ${matterId}:`, error);
            throw error;
        }
    }

    static async getIncompleteDeadlinesCount() {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/count-incomplete-deadlines`, { headers });
            return response.data;
        } catch (error) {
            console.error('Error fetching incomplete deadlines count:', error);
            throw error;
        }
    }

    static async addDeadline(deadlineData) {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_URL}/add-deadline`, deadlineData, { headers });
            return response.data;
        } catch (error) {
            console.error('Error adding deadline:', error);
            throw error;
        }
    }

    // Checklist Fetches

    static async getAllChecklistItems() {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/checklistitems`, { headers });
            return response.data;
        } catch (error) {
            console.error('Error fetching checklist items:', error);
            throw error;
        }
    }

    static async getChecklistItemsByMatterId(matterId) {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/checklistitems/${matterId}`, { headers });
            return response.data;
        } catch (error) {
            console.error(`Error fetching checklist items for matter ID ${matterId}:`, error);
            throw error;
        }
    }
    
    static async getIncompleteChecklistItemsCount() {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_URL}/count-incomplete-checklist-items`, { headers });
            return response.data;
        } catch (error) {
            console.error('Error fetching incomplete checklist items count:', error);
            throw error;
        }
    }

    static async addChecklistItem(checklistData) {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_URL}/addchecklistitems`, checklistData, { headers });
            return response.data;
        } catch (error) {
            console.error('Error adding checklist item:', error);
            throw error;
        }
    }
}

export default DeadlineChecklistService;
