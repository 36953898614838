import React from 'react';
import './PendingInvoices.css';

const PendingInvoices = () => {
    const total = 41; // Dynamic task count, if needed
    const Type = "Invoices Due"; // Dynamic task type, if needed   
    return (
        <div className="ID-container">
            <span className="ID-count">{total}</span>
            <span className="ID-label"> {Type}</span>
        </div>
    );
};

export default PendingInvoices;
