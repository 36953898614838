import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_BASE_URL; 

class NotificationsService {
    
    static async getNotifications() {
        try {
            const response = await axios.get(`${API_URL}/notifications`, {
                
                headers: await getAuthHeaders(),
            });
            console.log('Notifications:', response);
            return response;
        } catch (error) {
            console.error('Error fetching notifications:', error);
            throw new Error('An error occurred while fetching notifications.');
            
        }
    }
}

export default NotificationsService;