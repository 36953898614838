import React from "react";
import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CreateChecklistTopBar = ({ formData, handleChecklistCreation }) => {
  return (
    <div className="createMattersTopBar">
      <div id="leftSideCreateMattersTopBar">
        <Link to={`/tasks`}>
          <button className="mattersBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="mattersBackButton" />
          </button>
        </Link>
        <span>Create a Checklist</span>
      </div>
      <div id="rightSideCreateMattersTopBar">
        <button className="createMatterButton" onClick={handleChecklistCreation}>
          Create Checklist
        </button>
      </div>
    </div>
  );
};

export default CreateChecklistTopBar;
