import { Line } from "react-chartjs-2";
import "./FinancialGraph.css";

const FinancialGraph = ({lineData}) => {
  return (
    <div className="card financial-overview">
      <h5 className="financial-dashboard-head">Financial Overview</h5>
      <Line data={lineData} />
    </div>
  );
};

export default FinancialGraph;
