import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_BASE_URL;

class AgentsService {
  
  static async getAgents() {
    try {
      const response = await axios.get(`${API_URL}/agents`, {
        headers: await getAuthHeaders(),
      });
      console.log('Agents:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching agents:', error);
      throw new Error('An error occurred while fetching agents.');
    }
  }

  static async addAgent(agentData) {
    if (!API_URL) {
      throw new Error("API base URL is not defined");
    }
    
    try {
      const response = await fetch(`${API_URL}/agents`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(agentData),
      });

      if (!response.ok) {
        const errorData = await response.json();  // Assuming the server responds with JSON error data
        const error = new Error(`Error posting agent: ${response.statusText}`);
        error.response = {
          status: response.status,
          statusText: response.statusText,
          data: errorData,  // Attach the error data returned by the server
        };
        throw error; // This will be caught in React Query's onError
      }

      // If successful, return true or data
      return response.status === 201;
      
    } catch (error) {
      // If there's a network error or any other error, re-throw it to be caught by React Query's onError
      throw error;
    }
  }
}

export default AgentsService;
