import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays,
    isSameMonth,
    isSameDay
} from 'date-fns';
import './BillableCalender.css';

const BillableCalender = ({ data }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const renderHeader = () => (
        <div className="Billable-calendar-header">
            <button className="Billable-calendar-header-button" onClick={prevMonth}>
                <FaChevronLeft />
            </button>
            <span className="Billable-calendar-header-title">
                {format(currentMonth, 'MMMM yyyy')}
            </span>
            <button className="Billable-calendar-header-button" onClick={nextMonth}>
                <FaChevronRight />
            </button>
        </div>
    );

    const renderDays = () => {
        const days = [];
        const dateFormat = 'EEE';
        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="Billable-calendar-day-name" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="Billable-calendar-days-row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let days = [];
        let day = startDate;
        const dateFormat = 'd';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const dayEvents = data.filter(event => event.date && new Date(event.date).toDateString() === day.toDateString());

                let totalAmount = 0;
                let formattedTotalTime = '';

                if (dayEvents.length > 0) {
                    let totalSeconds = dayEvents.reduce((total, event) => {
                        const [hours, minutes, seconds] = event.time_recorded.split(':').map(Number);
                        return total + hours * 3600 + minutes * 60 + seconds;
                    }, 0);

                    const totalHours = Math.floor(totalSeconds / 3600);
                    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);

                    if (totalHours > 0) {
                        formattedTotalTime += `${totalHours}hr${totalHours > 1 ? 's' : ''} `;
                    }
                    if (totalMinutes > 0) {
                        formattedTotalTime += `${totalMinutes}m${totalMinutes > 1 ? 's' : ''}`;
                    }

                    totalAmount = dayEvents.reduce((sum, event) => sum + parseFloat(event.amount || 0), 0);
                }

                days.push(
                    <div
                        className={`Billable-calendar-cell ${!isSameMonth(day, monthStart) ? 'disabled' : ''} ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
                        key={day}
                        onClick={() => setSelectedDate(cloneDay)}
                    >
                        <span className="Billable-calendar-number">{formattedDate}</span>
                        <div className="Billable-calendar-tile-content">
                            {formattedTotalTime && <p className="total-time">{formattedTotalTime}</p>}
                            {totalAmount > 0 && <p className="event-amount">${totalAmount.toFixed(2)}</p>}
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(<div className="Billable-calendar-row" key={day}>{days}</div>);
            days = [];
        }

        return <div className="Billable-calendar-body">{rows}</div>;
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="Billable-calendar-container">
            <div className="Billable-calendar">
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
            <div className="Billable-sidebar">
                <h2>Events on {format(selectedDate, 'MMMM d, yyyy')}</h2>
                {data.filter(event => new Date(event.date).toDateString() === selectedDate.toDateString()).length > 0 ? (
                    data.filter(event => new Date(event.date).toDateString() === selectedDate.toDateString()).map((event, index) => (
                        <div key={index} className="Billable-sidebar-event">
                            <h3>{event.notes || 'Billable Time Entry'}</h3>
                            <p>Time Recorded: {event.time_recorded}</p>
                            <p>Amount: ${event.amount}</p>
                        </div>
                    ))
                ) : (
                    <p>No events for this day</p>
                )}
            </div>
        </div>
    );
};

export default BillableCalender;
