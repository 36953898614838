import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import "./DeadlineTable.css";
import DeadlineSideBar from './DeadlineSideBar/DeadlineSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const DeadlineTable = ({ data }) => {
  const { matterId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedDeadline, setSelectedDeadline] = useState(null);

  const openSidebar = (deadline) => {
    setSelectedDeadline(deadline);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedDeadline(null);
  };

  const truncateTitle = (title) => {
    if (!title || typeof title !== 'string') {
      return "";
    }
    const words = title.split(" ");
    return words.length > 9 ? words.slice(0, 9).join(" ") + " ..." : title;
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Deadline Type</th>
            <th>Due Date</th>
            <th>Matter ID</th>
            <th className="due-date-header">
              <span>Type</span>
              <Link to={`/matterDetails/${matterId}/tasks/createDeadline`} className="add-icon-link">
              <FontAwesomeIcon icon={faPlus} className="icon-button" aria-label="Add" />
              </Link>
            </th>    
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                <button
                  className="TaskLink"
                  title={row.deadline_type}
                  onClick={() => openSidebar(row)}
                >
                  {truncateTitle(row.deadline_type)}
                </button>
              </td>
              <td>{row.type}</td>
              <td>{row.due_date}</td>
              <td>{row.matterid}</td>
              <td>{row.internal_external}</td>
             
            </tr>
          ))}
        </tbody>
      </table>
      <DeadlineSideBar deadline={selectedDeadline} onClose={closeSidebar} isOpen={isSidebarOpen} />
    </div>
  );
};

export default DeadlineTable;
