import React from 'react';
import './DataBar.css'; 
import Total from './Components/Total/Total';
import Pending from './Components/Pending/Pending';
import InProgress from './Components/In-Progress/InProgress';
import Completed from './Components/Completed/Completed';

const DataBar = ({ setActiveTable }) => {
  const handleSelectChange = (event) => {
    setActiveTable(event.target.value); // Update active table on selection change
  };

  return (
    <div className="data-bar">
      <select 
        className="data-bar-dropdown"
        onChange={handleSelectChange} // Handle table selection
      >
        <option value="Tasks">Tasks</option>
        <option value="Deadlines">Deadlines</option>
        <option value="Checklist">Checklist</option>
      </select>

      <div className="data-bar-item">
        <Total />
      </div>
      <div className="data-bar-item">
        <Pending />
      </div>
      <div className="data-bar-item">
        <InProgress />
      </div>
      <div className="data-bar-item">
        <Completed />
      </div>
    </div>
  );
};

export default DataBar;
