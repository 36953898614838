import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import Select for dropdowns
import BillableTable from './BillableTable/BillableTable';
import BillableCalender from './BillableCalender/BillableCalender';
import FinancialService from '../../AppSettings/services/FinancialService';
import './BillableTime.css';
import { useMsal } from "@azure/msal-react";
import EmployeeService from '../../AppSettings/services/EmployeeService';
import MatterService from '../../AppSettings/services/MatterService';

const BillableTime = () => {
    const { accounts } = useMsal(); // Get logged-in user info
    const employeeid = accounts[0]?.idTokenClaims?.oid;

    const [employee, setEmployee] = useState([]);
    const [billedTimeData, setBilledTimeData] = useState([]);
    const [matters, setMatters] = useState([]);
    const [formData, setFormData] = useState({
        matterid: '',
        timeRecorded: '',
        date: new Date().toISOString().slice(0, 10),
        notes: '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [loadingMatters, setLoadingMatters] = useState(true);
    const [error, setError] = useState(null);
    const [isTableView, setIsTableView] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);

    // Fetch all billed time data
    useEffect(() => {
        const fetchBilledTime = async () => {
            try {
                setIsLoading(true);
                const data = await FinancialService.getAllBilledTime();
                setBilledTimeData(data);
            } catch (error) {
                console.error("Error fetching billed time data:", error);
                setError("Failed to fetch billed time data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchBilledTime();
        getEmployeeById(employeeid);
    }, [employeeid]);

    // Fetch available matters
    useEffect(() => {
        const fetchMatters = async () => {
            try {
                const data = await MatterService.getMatters();
                setMatters(
                    data.map((matter) => ({
                        value: matter.matterid,
                        label: `${matter.matterid}: ${matter.mattername}`,
                    }))
                );
            } catch (error) {
                console.error("Error fetching matters:", error);
                setError("Failed to load matters.");
            } finally {
                setLoadingMatters(false);
            }
        };

        fetchMatters();
    }, []);

    const getEmployeeById = async (employeeid) => {
        try {
            const employee = await EmployeeService.getEmployeeById(employeeid);
            setEmployee(employee);
        } catch (error) {
            console.error("Error fetching employee:", error);
            setError("Failed to load employee.");
        }
    };

    const handleMatterChange = (selectedOption) => {
        setFormData((prevState) => ({
            ...prevState,
            matterid: selectedOption ? selectedOption.value : '',
        }));
    };

    const handleInputChange = (field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const calculateAmount = (rate, time) => {
        const parsedRate = parseFloat(rate);
        if (isNaN(parsedRate)) {
            console.error('Invalid billedRate:', rate);
            return '0.00';
        }

        const [hours, minutes, seconds] = time.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            console.error('Invalid timeRecorded format:', time);
            return '0.00';
        }

        const totalHours = hours + minutes / 60 + seconds / 3600;
        const amount = parsedRate * totalHours;
        return amount.toFixed(2);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        const billedRate = employee?.billedrate || 0;
        const formattedTime = `${Math.floor(formData.timeRecorded)}:00:00`; // Ensure proper time format
        const amount = calculateAmount(billedRate, formattedTime); // Calculate amount based on the rate
    
        try {
            await FinancialService.addBilledTime({
                timeRecorded: formattedTime, // Match the backend field name
                date: formData.date,
                notes: formData.notes,
                matterId: formData.matterid, // Correct key as expected by backend
                employeeid, // Current user's employee ID
                amount, // Calculated amount
            });
    
            // Close form and refresh the table with new data
            setShowAddForm(false);
            setBilledTimeData((prevData) => [
                ...prevData,
                { ...formData, amount, employeeid },
            ]);
        } catch (error) {
            console.error("Error adding billed time entry:", error);
            setError("Failed to add billed time entry.");
        }
    };
    
    return (
        <div className="billable-container">
            <div className="billableTable-header">
                <h1>Billable Time</h1>
                <div className="billableTable-actions">
                    <button
                        className={`MF-toggle-view-button ${isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(true)}
                        aria-label="Table View"
                    >
                        <i className="fas fa-table"></i>
                    </button>
                    <button
                        className={`MF-toggle-view-button ${!isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(false)}
                        aria-label="Calendar View"
                    >
                        <i className="fas fa-calendar-alt"></i>
                    </button>
                    <button
                        className="MF-billableTable-add-button"
                        onClick={() => setShowAddForm(true)}
                    >
                        +
                    </button>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
                <p>Loading...</p>
            ) : isTableView ? (
                <BillableTable data={billedTimeData} />
            ) : (
                <BillableCalender data={billedTimeData} />
            )}

            {showAddForm && (
                <div className="Billable-add-billable-time-popup">
                    <div className="Billable-popup-content">
                        <h2>Add Billable Time</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className="Tasks-form-group">
                                <label>Matter ID</label>
                                <Select
                                    options={matters}
                                    onChange={handleMatterChange}
                                    isLoading={loadingMatters}
                                    placeholder="Select or search a matter..."
                                    isClearable
                                />
                            </div>
                            <label>
                                Time Recorded (Hours):
                                <input
                                    type="number"
                                    step="0.1"
                                    value={formData.timeRecorded}
                                    onChange={(e) =>
                                        handleInputChange('timeRecorded', e.target.value)
                                    }
                                    required
                                />
                            </label>
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={formData.date}
                                    onChange={(e) =>
                                        handleInputChange('date', e.target.value)
                                    }
                                    required
                                />
                            </label>
                            <label>
                                Notes:
                                <textarea
                                    value={formData.notes}
                                    onChange={(e) =>
                                        handleInputChange('notes', e.target.value)
                                    }
                                />
                            </label>
                            <button type="submit">Submit</button>
                            <button type="button" onClick={() => setShowAddForm(false)}>
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BillableTime;
