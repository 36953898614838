import "./TopBar.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../Clients/Components/TopBar/Component/IconButton";
import { useState, useEffect, useRef } from "react";

const FinancialsDetailsTopBar = ({ setActivePage }) => {
  const [menuOpen, setMenuOpen] = useState(false); // Track menu visibility
  const menuRef = useRef(null); // Ref to track the menu container

  const toggleMenu = () => setMenuOpen((prev) => !prev); // Toggle menu visibility

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="financialDetailsTopBar">
      <div id="leftSidefinancialDetailsTopBar">
      </div>

      <div id="rightSidefinancialDetailsTopBar" ref={menuRef}>
        <IconButton icon={faBars} ariaLabel="Bars" onClick={toggleMenu} />

        {menuOpen && (
          <div className="financials-dropdown-menu">
            <button onClick={() => setActivePage("dashboard")}>Dashboard</button>
            <button onClick={() => setActivePage("fixedFees")}>Fixed Fees</button>
            <button onClick={() => setActivePage("disbursements")}>Disbursements</button>
            <button onClick={() => setActivePage("billableTime")}>Billable Time</button>
            <button onClick={() => setActivePage("clients")}>Clients</button>
            <button onClick={() => setActivePage("agents")}>Agents</button>
            <button onClick={() => setActivePage("invoices")}>Invoices</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinancialsDetailsTopBar;

