import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query"; // Ensure the correct version is used
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MatterService from "../../../../../../../AppSettings/services/MatterService";
import "./MetaData.css";

const MetaData = () => {
    const { matterId } = useParams();
    const [matter, setMatter] = useState(null);

    // Update useQuery to use the object form
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["Matters", matterId], // Key for caching
        queryFn: () => MatterService.getMatterDetails(matterId), // Function to fetch data
        enabled: !!matterId, // Run only if matterId exists
    });

    useEffect(() => {
        if (data) {
            setMatter(data); // Update local state with fetched data
        }
    }, [data]);

    // Handle loading and error states
    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    if (isError) {
        return (
            <div className="error">
                <p>Error: {error.message || "Something went wrong"}</p>
            </div>
        );
    }

    return (
        <div>
            <div className="matter-MetaData-row-top">
                <div className="matter-MetaData-container">
                    <h2 className="matter-MetaData-type">Publication</h2>
                    <p className="matter-MetaData-text">
                        <strong>Number:</strong> {matter?.publication_no || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Date:</strong> {matter?.publication_date || "Not Assigned"}
                    </p>
                </div>
                <div className="matter-MetaData-container">
                    <h2 className="matter-MetaData-type">Registration</h2>
                    <p className="matter-MetaData-text">
                        <strong>Number:</strong> {matter?.registration_no || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Date:</strong> {matter?.registration_date || "Not Assigned"}
                    </p>
                </div>
                <div className="matter-MetaData-container">
                    <h2 className="matter-MetaData-type">IP & Case Type</h2>
                    <p className="matter-MetaData-text">
                        <strong>IP Type:</strong> {matter?.mattertype || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Case Type:</strong> {matter?.case_type || "Not Assigned"}
                    </p>
                </div>
            </div>
            <div className="matter-MetaData-row-bottom">
                <div className="matter-MetaData-container">
                    <h2 className="matter-MetaData-type">Application</h2>
                    <p className="matter-MetaData-text">
                        <strong>Number:</strong> {matter?.application_no || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Date:</strong> {matter?.application_date || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Status:</strong> {matter?.application_status || "Not Assigned"}
                    </p>
                </div>
                <div className="matter-MetaData-container">
                    <h2 className="matter-MetaData-type">Office Action & Expiry Date</h2>
                    <p className="matter-MetaData-text">
                        <strong>Office Action Date:</strong>{" "}
                        {matter?.office_action_date || "Not Assigned"}
                    </p>
                    <p className="matter-MetaData-text">
                        <strong>Expiry Date:</strong> {matter?.expiry_date || "Not Assigned"}
                    </p>
                </div>
            </div>
        </div>
    );
    
};

export default MetaData;
