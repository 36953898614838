import "./GeneralFixedFees.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const testData = [
  {
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },
  {
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },
  {
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },
  {
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },{
    feeCode: "S0034",
    narrative: "testing testing testing text",
    amount: "$2000",
    date: "October 12th, 2024",
  },
];

const PAGELIMIT = 12;

const FixedFees = () => {
    const [currPage, setCurrPage] = useState(1);
    
    const handlePageDecrement = () => {
        if (currPage > 1) {
            // Probably do something on matters page as a result of page state changing
            setCurrPage(currPage - 1);
        }
    }

    const handlePageIncrement = () => {
        if (currPage < PAGELIMIT) {
            // Probably do something on matters page as a result of page state changing
            setCurrPage(currPage + 1);
        }
    }

  return (
    <div className="fixedFees-container">
      <div className="fixedFees-header">
        <h1>Fixed Fees</h1>
        <button className="fixedFees-add-button">+</button>
      </div>
      <div className="fee-table-container">
        <table className="fee-table">
          <thead>
            <tr>
              <th>Fee Code</th>
              <th>Narrative</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {testData.map((row, index) => (
              <tr key={index}>
                <td>
                  <button className="fee-code">{row.feeCode}</button>
                </td>
                <td>{row.narrative}</td>
                <td>{row.amount}</td>
                <td>{row.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fee-footer">
      <div>
        <button className="fee-pagination-button" onClick={handlePageDecrement}>
          <FontAwesomeIcon icon={faAnglesLeft} className="fee-pagination-button" />
        </button>
        <span>{currPage} of {PAGELIMIT}</span>
        <button className="fee-pagination-button" onClick={handlePageIncrement}>
          <FontAwesomeIcon icon={faAnglesRight} className="fee-pagination-button" />
        </button>
      </div>
    </div>
    </div>
  );
};

export default FixedFees;
