import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DeadlineChecklistService from '../../../../../AppSettings/services/DeadlineChecklistService';
import TaskService from '../../../../../AppSettings/services/TaskService';
import './DTCCount.css'; // Ensure the CSS file is created for styling

const DTCCount = () => {
    const [counts, setCounts] = useState({
        incompleteDeadlines: 0,
        incompleteChecklistItems: 0,
        pendingTasks: 0,
        inProgressTasks: 0,
    });

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const deadlinesCount = await DeadlineChecklistService.getIncompleteDeadlinesCount();
                const checklistCount = await DeadlineChecklistService.getIncompleteChecklistItemsCount();
                const taskStatusData = await TaskService.getTaskStatusDistribution();

                const pendingTasks = taskStatusData.find(task => task.status === 'Pending')?.count || 0;
                const inProgressTasks = taskStatusData.find(task => task.status === 'In Progress')?.count || 0;

                setCounts({
                    incompleteDeadlines: deadlinesCount.incomplete_deadlines_count,
                    incompleteChecklistItems: checklistCount.incomplete_checklist_items_count,
                    pendingTasks,
                    inProgressTasks,
                });
            } catch (error) {
                console.error('Error fetching radar counts:', error);
            }
        };

        fetchCounts();
    }, []);

    const totalOnTask =  
    counts.pendingTasks+
    counts.inProgressTasks;


    return (
        <div className="DTC-count-container">
            <Link to="/tasks" >
            <div className="DTC-count-item">
                <span className="DTC-count-number">{counts.incompleteDeadlines}</span>
                <span className="DTC-count-label">Deadlines</span>
            </div>
            <div className="DTC-count-item">
                <span className="DTC-count-number">{totalOnTask}</span>
                <span className="DTC-count-label">Tasks</span>
            </div>
            <div className="DTC-count-item">
                <span className="DTC-count-number">{counts.incompleteChecklistItems}</span>
                <span className="DTC-count-label">Checklist Items</span>
            </div>
            </Link>
        </div>
    );
};

export default DTCCount;
