import React from 'react';
import RecentActivities from './RecentActivities/RecentActivites';
import MatterStatus from './MatterStatusGraph/MatterStatus';
import './MatterWidget.css'; // Import a CSS file for styling

// Define your MatterWidget component
const MatterWidget = () => {
    return (
        <div className="matter-widget-container">
            <h2 className="recent-activities-title">Recent Activities</h2>
            <div className="content-container">
                <MatterStatus />
                <RecentActivities />
            </div>
        </div>
    );
};

// Export the MatterWidget component
export default MatterWidget;
