import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class ReportingService {    

    static async getAllClientsExcel(selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/allClients/excel`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching all clients Excel report:', error);
            throw new Error('An error occurred while fetching the clients report.');
        }
    }

    static async getAllAgentsExcel(selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/allAgents/excel`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching all agents Excel report:', error);
            throw new Error('An error occurred while fetching the agents report.');
        }
    }

    static async getMattersByEmployeeIdExcel(employeeId, selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/mymatters/excel/${employeeId}`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching matters by employee ID Excel report:', error);
            throw new Error('An error occurred while fetching the matters report for the employee.');
        }
    }
    static async getAllMattersExcel(selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/allmatters/excel`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching all matters Excel report:', error);
            throw new Error('An error occurred while fetching the matters report.');
        }
    }

    static async getAllTasksExcel(selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/allTasks/excel`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching all tasks Excel report:', error);
            throw new Error('An error occurred while fetching the tasks report.');
        }
    }

    static async getTasksByEmployeeIdExcel(employeeId, selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/mytasks/excel/${employeeId}`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching tasks by employee ID Excel report:', error);
            throw new Error('An error occurred while fetching the tasks report for the employee.');
        }
    }

    static async getTasksByMatterExcel(matterId, selectedColumns) {
        try {
            const response = await axios.post(
                `${API_URL}/tasks_by_matter/excel/${matterId}`, 
                { cols: selectedColumns },
                { headers: await getAuthHeaders() }
            );
            return response;
        } catch (error) {
            console.error('Error fetching tasks by matter ID Excel report:', error);
            throw new Error('An error occurred while fetching the tasks report for the matter.');
        }
    }
}

export default ReportingService;
