import React, { useEffect, useState } from 'react';
import TaskService from '../../../AppSettings/services/TaskService';
import './TaskWidget.css'; // Make sure you import the CSS file
import { useQuery } from '@tanstack/react-query';

function TaskWidget() {
    const [taskStatus, setTaskStatus] = useState({
        pending: 0,
        inProgress: 0,
        completed: 0
    });

    const { data, isPending, error } = useQuery({
        queryKey: ['Tasks'],
        queryFn: TaskService.getTaskStatusDistribution,
    })

    useEffect(() => {
        if (data) {
            const statusCounts = data.reduce((acc, { status, count }) => {
                const key = status.toLowerCase().replace(' ', ''); // Normalize key
                acc[key] = count;
                return acc;
            }, { pending: 0, inprogress: 0, completed: 0 });

            setTaskStatus(statusCounts);   
        }
    }, [data])

    if (isPending) {
        return <span>Loading...</span>
    }
    
    if (error) {
        return <span>Error: {error.message}</span>
    }

    return (
        <div className="task-widget">
            <h1 className="task-widget-title">Tasks</h1>
            <div className="task-status">
                <div className="task pending">
                    <div className="task-status-bar task-pending-bar"></div>
                    <div className="task-data">
                        <h2 className="task-data-title">Pending</h2>
                        <p className="task-data-input">{taskStatus.pending}</p>
                        <div className="task-lines-container">
                            {Array.from({ length: 23 }).map((_, index) => (
                                <div key={index} className="task-line task-pending-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="task in-progress">
                    <div className="task-status-bar task-in-progress-bar"></div>
                    <div className="task-data">
                        <h2 className="task-data-title">In Progress</h2>
                        <p className="task-data-input">{taskStatus.inprogress}</p>
                        <div className="task-lines-container">
                            {Array.from({ length: 23 }).map((_, index) => (
                                <div key={index} className="task-line task-in-progress-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="task completed">
                    <div className="task-status-bar task-completed-bar"></div>
                    <div className="task-data">
                        <h2 className="task-data-title">Completed</h2>
                        <p className="task-data-input">{taskStatus.completed}</p>
                        <div className="task-lines-container">
                            {Array.from({ length: 23 }).map((_, index) => (
                                <div key={index} className="task-line task-completed-line"></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaskWidget;