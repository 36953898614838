import { useState, useEffect } from "react";
import "./ClientsPage.css";
import ClientsTopBar from "./Components/TopBar/TopBar.js";
import ClientsTable from "./Components/ClientsTable/ClientsTable.js";
import ClientsPagination from "./Components/Pagination/Pagination";
import DataBar from "./Components/DataBar/DataBar.js";
import { useQuery } from "@tanstack/react-query";
import ClientService from "../AppSettings/services/ClientService.js";

const columns = {
  "Client ID": "ClientID",
  Company: "Company",
  Email: "Email",
  Phone: "Phone",
  Address: "Address"
};

const ClientsPage = () => {
  const { data, isPending, error } = useQuery({
    queryKey: ["Clients"],
    queryFn: ClientService.getClients,
  });

  const [filteredTasks, setFilteredTasks] = useState(data);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);

  useEffect(() => {
    if (data) {
      console.log(data);
      setFilteredTasks(data);
      setPageLimit(Math.ceil(data.length / 5));
    }
  }, [data])

  const handleSearch = (search, filter) => {
    if (!filter) {
      return;
    }

    let filtered;
    if (filter === "Client") {
      filtered = data.filter((entry) =>
        entry.FirstName.toLowerCase().includes(search.toLowerCase()) || entry.LastName.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      filtered = data.filter((entry) =>
        entry[columns[filter]].toLowerCase().includes(search.toLowerCase())
      );
    }
    const newPageLimit = Math.ceil(filtered.length / 5)
    setPageLimit(newPageLimit ? newPageLimit : 1);
    setFilteredTasks(filtered);
    setPage(1);
  }

  const handlePagination = (page) => {
    setPage(page);
  }

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="tasksPage">
      <ClientsTopBar onSearch={handleSearch}></ClientsTopBar>
      <DataBar data={filteredTasks}></DataBar>
      <ClientsTable data={filteredTasks} ></ClientsTable>
      <ClientsPagination currPage={page} onPageChange={handlePagination} pageLimit={pageLimit}></ClientsPagination>
    </div>
  );
};

export default ClientsPage;
