import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ClientService from "../../../AppSettings/services/ClientService";
import { useMutation } from "@tanstack/react-query";

const CreateTasksTopBar = ({ formData, setError, setFormData, setSuccess }) => {
  const mutation = useMutation({
    mutationFn: () => ClientService.postClient(formData),
    onSuccess: (data) => {
      // Handle the successful mutation (e.g., show a success message or refetch data)
      console.log("Client created successfully:", data);
      setFormData({
        FirstName: "",
        LastName: "",
        Company: "",
        Email: "",
        Phone: "",
        Address: "",
      });
      setError("");
      setSuccess("Successfully created new client!");
      setTimeout(() => setSuccess(""), 5000);
    },
    onError: (error) => {
      // Handle the error (e.g., show an error message)
      console.error("Error creating client:", error);
      setError(`Error: ${error.response.data.error}`);
    },
  })

  const handleSubmit = () => {
    console.log(formData);
    // Trigger the mutation with data
    mutation.mutate(formData);
  }

  return (
    <div className="createMattersTopBar">
      <div id="leftSideCreateMattersTopBar">
        <Link to="/clients">
          <button className="mattersBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="mattersBackButton" />
          </button>
        </Link>
        <span>Create a Client</span>
      </div>
      <div id="rightSideCreateMattersTopBar">
        <button className="createMatterButton" onClick={handleSubmit}>Create Client</button>
      </div>
    </div>
  );
};

export default CreateTasksTopBar;
