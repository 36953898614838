import { useEffect, useState } from "react";
import TopBar from "./Components/TopBar/TopBar";
import "./MattersPage.css";
import MattersTable from "./Components/MattersTable/MattersTable";
import MattersPagination from "./Components/Pagination/Pagination";
import { useQuery } from "@tanstack/react-query";
import MatterService from "../AppSettings/services/MatterService";
import { useMsal } from "@azure/msal-react";

const columns = {
  Status: "status",
  Client: "clientname",
  Leader: "employeename",
  "Open Date": "opendate",
};

const MattersPage = () => {
  const { accounts } = useMsal(); // Get logged-in user info
  const employeeId = accounts[0]?.idTokenClaims?.oid;

  const [filteredMatters, setFilteredMatters] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const [showMyMatters, setShowMyMatters] = useState(true); // State to toggle between "My Matters" and "All Matters"

  // Fetch matters dynamically based on "My Matters" or "All Matters" toggle
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["Matters", showMyMatters],
    queryFn: () =>
      showMyMatters && employeeId
        ? MatterService.getMattersByEmployee(employeeId)
        : MatterService.getMatters(),
  });

  // When data changes, update filtered matters and pagination limits
  useEffect(() => {
    if (data) {
      // Sort data by the numeric portion at the end of "matterid"
      const sortedData = [...data].sort((a, b) => {
        // Extract numeric portions from "matterid"
        const numA = parseInt(a.matterid.split("-").pop(), 10);
        const numB = parseInt(b.matterid.split("-").pop(), 10);
        
        return numA - numB; // Compare numerically
      });
  
      setFilteredMatters(sortedData); // Set the sorted data
      setPageLimit(Math.max(1, Math.ceil(sortedData.length / 5))); // Update pagination limits
    }
  }, [data]);
    const handleSearch = (search, filter) => {
    if (!filter) {
      return;
    }

    let filtered;
    if (filter === "Matters") {
      filtered = data.filter(
        (entry) =>
          entry.matterid.toLowerCase().includes(search.toLowerCase()) ||
          entry.mattername.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      filtered = data.filter((entry) =>
        entry[columns[filter]].toLowerCase().includes(search.toLowerCase())
      );
    }
    setFilteredMatters(filtered);
    setPageLimit(Math.max(1, Math.ceil(filtered.length / 5))); // Ensure at least one page
    setPage(1);
  };

  const handlePagination = (page) => setPage(page);

  const handleToggleMatters = (showMyMatters) => {
    setShowMyMatters(showMyMatters); // Toggle between "My Matters" and "All Matters"
    setPage(1);
    refetch(); // Refetch the matters based on the new toggle state
  };

  if (isPending) return <span>Loading...</span>;
  if (error) return <span>Error: {error.message}</span>;

  return (
    <div className="mattersPage">
      <TopBar onSearch={handleSearch} onToggleMatters={handleToggleMatters} />
      <MattersTable
        data={filteredMatters.slice((page - 1) * 5, page * 5)}
        showLeader={!showMyMatters} // Show Leader column only for "All Matters"
      />
      <MattersPagination
        currPage={page}
        onPageChange={handlePagination}
        pageLimit={pageLimit}
      />
    </div>
  );
};

export default MattersPage;
