import { Auth } from 'aws-amplify';
// import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../../../index";

const getAuthHeaders = async () => {
    // const { instance, accounts } = useMsal();
    try {
      const accounts = msalInstance.getAllAccounts();
      const silentRequest = {
        scopes: ["User.Read"],
        account: accounts[0]
      };
      const authResponse = await msalInstance.acquireTokenSilent(silentRequest);
      // const authResponse = await msalInstance.acquireTokenPopup(silentRequest);
      const accessToken = authResponse.accessToken;
      
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'API-key': process.env.REACT_APP_API_KEY
      };
  
      return headers;
    } catch (error) {
      // Handle error (e.g., user not authenticated)
      console.error('Error:', error);
      return null;
    }
  };
  
  export default getAuthHeaders;