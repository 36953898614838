import React, { useState, useEffect } from 'react';
import './DCTable.css';
import DeadlineChecklistService from '../../../../../AppSettings/services/DeadlineChecklistService';
import { useParams } from 'react-router-dom';

const DCTable = () => {
    const [data, setData] = useState([]);
    const [view, setView] = useState('Deadlines');
    const { matterId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = view === 'Deadlines'
                    ? await DeadlineChecklistService.getDeadlinesByMatterId(matterId)
                    : await DeadlineChecklistService.getChecklistItemsByMatterId(matterId);
                setData(response);
            } catch (error) {
                console.error(`Error fetching ${view.toLowerCase()} data:`, error);
            }
        };
        fetchData();
    }, [view, matterId]);

    return (
        <div className="dc-container">
            <div className="dc-header">
                <div className="dc-left">
                    <span 
                        className={view === 'Deadlines' ? 'active' : ''} 
                        onClick={() => setView('Deadlines')}
                    >
                        Deadlines
                    </span>
                    <span className="separator">|</span>
                    <span 
                        className={view === 'Checklist' ? 'active' : ''} 
                        onClick={() => setView('Checklist')}
                    >
                        Checklist
                    </span>
                </div>
                <button className="dc-add-button">+</button>
            </div>
         
            {data.slice(0, 3).map((item, index) => (
                <div key={index} className="dc-item">
                    <div className="dc-title">
                        {view === 'Deadlines' ? item.deadline_type : item.description}
                    </div>
                    <div className="dc-meta">
                        {view === 'Deadlines' ? (
                            <>
                                <span>Status: {item.status}</span>
                                {item.due_date && <span className="dc-assigned">Due Date: {item.due_date}</span>}
                            </>
                        ) : (
                            <>
                                <span>Status: {item.status}</span>
                                {item.matterid && <span className="dc-assigned">ID: {item.matterid}</span>}
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DCTable;
