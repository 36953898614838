import React, { useState, useEffect } from 'react';
import './ClientCount.css';
import ClientService from '../../../AppSettings/services/ClientService';

const ClientCount = () => {
    const [clientCount, setClientCount] = useState(0);

    const fetchClientCount = async () => {
        try {
            const response = await ClientService.getClientCount();  
            setClientCount(response);
        } catch (error) {
          console.log('Error fetching clients:', error);
        }
      };
    
      useEffect(() => {
        fetchClientCount();
      }, []);

    return (
        <div>
         <div className="client-count-container">
            <span className="client-count">{clientCount}</span>
            <span className="client-label">Your Client</span>
        </div>
        </div>
    );
};

export default ClientCount;