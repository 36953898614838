import React from 'react';
import './BilliableTarget.css';

const BilliableTarget = ({ progress = 80 }) => {
    return (
        <div className="BT-container">
            <div className="BT-bar" style={{ width: `${progress}%` }}></div>
            <span className="BT-label">Billable Target</span> 
        </div>
    );
};

export default BilliableTarget;
