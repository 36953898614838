import React, { useState, useEffect } from 'react';
import './ClientDetails.css';
import 'font-awesome/css/font-awesome.min.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import ClientMatters from './Components/ClientMatters';
import ClientTopBar from './Components/ClientTopBar';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const ClientDetails = () => {
    const [activeTab, setActiveTab] = useState('Client');

  return (
    <div className="ClientDetails-Page">
     <Link to="/clients" className="matterDetails-back-button">
        <FaArrowLeft />
      </Link>
      <div>
<ClientTopBar/>
<ClientMatters/>
      </div>
    </div>
  );
};

export default ClientDetails;
