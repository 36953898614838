import React, { useEffect } from 'react';
import './DeadlineSideBar.css';

const DeadlineSideBar = ({ deadline, onClose, isOpen }) => {
  // Log to confirm `isOpen` is changing
  useEffect(() => {
    console.log("Sidebar open state:", isOpen);
  }, [isOpen]);

  if (!deadline || !isOpen) return null; // Avoid rendering if no deadline is selected or sidebar is closed

  return (
    <div className={`Deadline-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="Deadline-header">
        <h2>Deadline Details</h2>
        <button className="Deadline-close-btn" onClick={onClose}>✕</button>
      </div>
    
      <div className="Deadline-Sidebar-contents">
        <div className="Deadline-details-container">
          <div className="Deadline-title">
            <h3>{deadline.deadline_type}</h3>
          </div>
          
          <div className="Deadline-meta">
            <p><strong>Due</strong> - <span>{deadline.due_date}</span></p>
            <p><strong>Internal/External</strong> - <span>{deadline.internal_external}</span></p>
          </div>
        </div>

        <div className="Deadline-info">
          <div className="Deadline-info-row">
              <p><strong>Status -</strong> {deadline.status}</p>
              <p><strong>ID -</strong> {deadline.matterid}</p>
          </div>
          <p><strong>Description -</strong> {deadline.type}</p>
        </div>

      </div>
    </div>
  );
};

export default DeadlineSideBar;
