import { useQuery } from "@tanstack/react-query";
import FinancialService from "../../../../../AppSettings/services/FinancialService";
import "./AddFixedFeeModal.css";
import { useParams } from "react-router-dom";
import { useState } from "react";

const AddFixedFeeModal = ({ onClose, onAddSuccess }) => {
  const { matterId } = useParams();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["fixedFees"],
    queryFn: FinancialService.getFixedFees,
  });

  const [isAddingNewFee, setIsAddingNewFee] = useState(false);
  const [newFeeData, setNewFeeData] = useState({
    fee_code: "",
    narrative: "",
    fees: ""
  });

  const handleAddFee = async (entry) => {
    try {
      // Call API to add the new fixed fee
      await FinancialService.addAssignedFixedFees({...entry, matterid: matterId});
      onAddSuccess(); // Notify parent component to refresh data
      onClose(); // Close modal
    } catch (err) {
      console.error("Error adding fixed fee:", err);
    }
  };

  const handleNewFeeSubmit = async (e) => {
    e.preventDefault();
    console.log(newFeeData);
    await FinancialService.addFixedFee(newFeeData);
    refetch();
    setNewFeeData({
      fee_code: "",
      narrative: "",
      fees: ""
    })
  };

  const handlePositiveNumberInput = (e) => {
    const value = e.target.value;
    setNewFeeData({
      ...newFeeData,
      fees: value && Math.abs(value) >= 0 ? Math.abs(value) : null
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching existing fixed fees</div>;
  }

  return (
    <div className="Fixed-fees-overlay">
      <div className="Fixed-fees-modal">
        <button className="fixed-fee-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="title-button-container">
          <h2>Add Fixed Fee</h2>
          <button className="new-fee-button" onClick={() => setIsAddingNewFee(!isAddingNewFee)}>
            {isAddingNewFee ? <>Hide Create New Fixed Fee</> : <>Create New Fixed Fee</>}
          </button>
        </div>


        {isAddingNewFee && (
          <form className="new-fee-form" onSubmit={handleNewFeeSubmit}>
            <input
              type="text"
              placeholder="Fee Code"
              value={newFeeData.fee_code}
              onChange={(e) => setNewFeeData({ ...newFeeData, fee_code: e.target.value })}
              required
            />
            <input
              type="text"
              placeholder="Narrative"
              value={newFeeData.narrative}
              onChange={(e) => setNewFeeData({ ...newFeeData, narrative: e.target.value })}
              required
            />
            <input
              type="number"
              min="0" 
              onInput={handlePositiveNumberInput}
              placeholder="Fees"
              value={newFeeData.fees}
              required
            />
            <button type="submit" className="submit-fee-button">Submit</button>
          </form>
        )}
        <div className="fixedFeesList">
          {data.map((entry, index) => (
            <div key={index} className="fee-entry">
              <span>
                {entry.fee_code} - {entry.narrative} - ${entry.fees}
              </span>
              <button
                className="add-fee-button"
                onClick={() => handleAddFee(entry)}
              >
               +
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddFixedFeeModal;
