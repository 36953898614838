import IconButton from "./Component/IconButton";
import React, { useState, useRef } from "react";
import "./TopBar.css";
import "./Component/IconButton";
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import SearchBar from "./Component/SearchBar";
import ExportClients from "./Component/ExportClients";
const TopBar = ({ onSearch }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const modalContentRef = useRef(null);

  const handleUploadClick = () => {
    setShowExportModal(true);
  };

  const closeModal = () => {
    setShowExportModal(false);
  };

  const handleOverlayClick = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      closeModal();
    }
  };
  return (
    <div className="topBar">
      <div id="leftSide">
        <Link to="/createClient">
            <IconButton icon={faPlus} ariaLabel="Add" />
        </Link>
      </div>
      <div id="rightSide">
      <IconButton icon={faUpload} ariaLabel="Upload" onClick={handleUploadClick} />
        <SearchBar onSearch={onSearch}></SearchBar>
      </div>

      {showExportModal && (
        <div className="modal" onClick={handleOverlayClick}>
          <div className="modal-content" ref={modalContentRef}>
            <ExportClients />
          </div>
        </div>
      )}

    </div>
  );
};

export default TopBar;
