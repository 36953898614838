import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./DisbursementPage.css";
import DisbursementTable from "./Components/DisbursementTable.js";
import AddDisbursement from "./Components/AddDisbursement.js";
import FinancialService from "../../../../AppSettings/services/FinancialService.js";

const DisbursementPage = () => {
  const { matterId } = useParams();
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddDisbursementVisible, setIsAddDisbursementVisible] = useState(false); // State for popup visibility

  useEffect(() => {
    const fetchDisbursements = async () => {
      try {
        const disbursements = await FinancialService.getAgentDisbursements(matterId);
        setData(disbursements);
        setFilteredTasks(disbursements);
      } catch (error) {
        console.error("Error fetching disbursements:", error);
      }
    };

    fetchDisbursements();
  }, [matterId]);

  const handleSearch = (search) => {
    setSearchTerm(search);
    const filtered = data.filter((entry) =>
      entry.matter.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredTasks(filtered);
  };

  const handleAddDisbursementSuccess = () => {
    // Refresh the disbursement list after adding a new entry
    const fetchDisbursements = async () => {
      try {
        const disbursements = await FinancialService.getAgentDisbursements(matterId);
        setData(disbursements);
        setFilteredTasks(disbursements);
      } catch (error) {
        console.error("Error refreshing disbursements:", error);
      }
    };
    fetchDisbursements();
  };

  return (
    <div className="DisbursementPage">
      <div className="Disbursement-header">
        <h1>Disbursements</h1>
        <button
          className="Disbursement-add-button"
          onClick={() => setIsAddDisbursementVisible(true)} // Show the AddDisbursement popup
        >
          +
        </button>
      </div>
      <DisbursementTable data={filteredTasks} />

      {/* Show AddDisbursement popup when the button is clicked */}
      {isAddDisbursementVisible && (
        <AddDisbursement
          onClose={() => setIsAddDisbursementVisible(false)} // Close the popup
          onAddSuccess={handleAddDisbursementSuccess} // Refresh the list after a successful add
        />
      )}
    </div>
  );
};

export default DisbursementPage;
