import React from 'react';
import ReactDOM from 'react-dom';
import './AppSettings/routes/index.css';
import App from './AppSettings/routes/App';
import reportWebVitals from './AppSettings/routes/reportWebVitals'; // Keep this import
import WebFontLoader from 'webfontloader';
// import { Amplify } from 'aws-amplify';
// import config from './aws-exports';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { BrowserRouter as Router } from "react-router-dom";

// Amplify.configure(config);

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./AppSettings/routes/config/auth-config";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = ReactDOM.createRoot(container);

  root.render(
    <Router>
      {/* <ThemeProvider theme={theme}> */}
      <App pca={msalInstance} />
      {/* </ThemeProvider> */}
    </Router>
  );
});

WebFontLoader.load({
  google: {
    families: ['Raleway:400,700'] // Specify the font and its weights
  }
});

// Measure performance and log results (you can replace `console.log` with any other function)
reportWebVitals(console.log);


// const rootElement = document.getElementById('root');

// Use createRoot from "react-dom/client" instead of ReactDOM.render
// ReactDOM.createRoot(rootElement).render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// reportWebVitals();
