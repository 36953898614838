import React from 'react';
import { useState, useEffect } from "react";
import './MatterInfo.css'; // Import the CSS file
import { useParams } from 'react-router-dom';
import MatterService from '../../../../../AppSettings/services/MatterService';
import { useQuery } from "@tanstack/react-query";

const MatterInfo = () => {
    const { matterId } = useParams();
    const [matter, setMatter] = useState(null);

    const { data, isPending, error } = useQuery({
        queryKey: ["Matters", matterId],  // Include matterId in the queryKey
        queryFn: () => MatterService.getMatterDetails(matterId), // Call the service with matterId
        enabled: !!matterId // Run the query only if matterId is defined
    });
    
    useEffect(() => {
        if (data) {
          setMatter(data);
        }
    }, [data])

    if (isPending) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <div className="matter-info-container">
            <h2 className="matter-info-type">Type: {matter?.mattertype}</h2>
            <p className="matter-info-text">
                <strong>Leader:</strong> {matter?.employee_name}
            </p>
            <p className="matter-info-text">
                <strong>Country:</strong> {matter?.country}
            </p>
            <p className="matter-info-text">
                <strong>Created:</strong> {matter?.opendate}
            </p>
            <p className="matter-info-text">
                <strong>Due:</strong> {matter?.closedate}
            </p>
        </div>
    );
};

export default MatterInfo;
