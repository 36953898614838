
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import "./DisbursementTable.css";

const DisbursementTable = ({ data }) => {
    return (
        <div className="Disbursement-table-container">
        <table className="Disbursement-table">
          <thead>
            <tr>
              <th>Agent</th>
              <th>Description</th>
              <th>Fees</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>
                  <button className="Disbursement-Link">{row.agentid}</button>
                </td>
                <td>{row.description}</td>
                <td>
                {row.fees}
                </td>
                <td>{row.actions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
}

export default DisbursementTable