import React from 'react';
import './DeadlineGraph.css';

const DeadlineGraph = () => {
  const data = [60, 30, 70, 50, 100, 80, 20]; // Example data for the bars
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  return (
    <div className="DC-graph-container">
      {data.map((value, index) => (
        <div key={index} className="DC-column">
          <div className="DC-bar-container">
            <div className="DC-ball"></div> {/* Ball above bar */}
            <div
              className="DC-bar"
              style={{ height: `${value * 2}px` }}
            ></div> {/* Bar */}
          </div>
          <div className="DC-day-label">{days[index]}</div>
        </div>
      ))}
    </div>
  );
};

export default DeadlineGraph;

