import React, { useEffect, useState } from 'react';
import './Total.css';
import TaskService from '../../../../../../../../AppSettings/services/TaskService';
import { useParams } from "react-router-dom";

const Total = () => {
    const [totalCount, setTotalCount] = useState(0);
    const { matterId } = useParams();

    useEffect(() => {
        const fetchTotalTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistributionByMatterId(matterId);

                // Sum the 'count' for all tasks with status 'Pending', 'In Progress', or 'Completed'
                const total = data
                    .filter(task =>
                        task.status === 'Pending' || 
                        task.status === 'In Progress' || 
                        task.status === 'Completed'
                    )
                    .reduce((acc, task) => acc + task.count, 0);

                setTotalCount(total);
            } catch (error) {
                console.error('Error fetching total tasks:', error);
            }
        };

        fetchTotalTasks();
    }, [matterId]);

    return (
        <div className="total-container">
            <span className="total-count">{totalCount}</span>
            <span className="total-label">Total Tasks</span>
        </div>
    );
};

export default Total;
