import { useQuery } from "@tanstack/react-query";
import FinancialService from "../../../AppSettings/services/FinancialService";
import Select from "react-select";
import "./AddDisbursement.css";
import MatterService from "../../../AppSettings/services/MatterService";
import { useState, useEffect, useRef } from "react";
import getAuthHeaders from "../../../AppSettings/routes/functions/getAuthHeaders";

const AddDisbursement = ({ onClose, onAddSuccess }) => {
  const [error, setError] = useState("");
  const [agents, setAgents] = useState([]);
  const [agentsLoading, setAgentsLoading] = useState(true);
  const [agentsError, setAgentsError] = useState(null);
  const [matters, setMatters] = useState([]);
  const [loadingMatters, setLoadingMatters] = useState(true);
  const [newDisbursementData, setNewDisbursementData] = useState({
    agentid: "",
    matterid: "",
    description: "",
    fees: "",
    date_added: null,
  });

  const modalRef = useRef(null); // Reference to the modal container

  // Fetch agents on mount
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        setAgentsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agents`, {
          headers: await getAuthHeaders(),
        });
        const data = await response.json();
        setAgents(
          data.map((agent) => ({
            value: agent.AgentID,
            label: `${agent.AgentID}: ${agent.FirstName} ${agent.LastName}`,
          }))
        );
      } catch (err) {
        setAgentsError("Error fetching agents");
      } finally {
        setAgentsLoading(false);
      }
    };

    fetchAgents();
  }, []);

  // Fetch matters on mount
  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const data = await MatterService.getMatters();
        setMatters(
          data.map((matter) => ({
            value: matter.matterid,
            label: `${matter.matterid}: ${matter.mattername}`,
          }))
        );
      } catch (error) {
        setError("Failed to load matters.");
      } finally {
        setLoadingMatters(false);
      }
    };

    fetchMatters();
  }, []);

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSelectChange = (selectedOption) => {
    setNewDisbursementData({
      ...newDisbursementData,
      agentid: selectedOption ? selectedOption.value : "",
    });
  };

  const handleMatterChange = (selectedOption) => {
    setNewDisbursementData({
      ...newDisbursementData,
      matterid: selectedOption ? selectedOption.value : "",
    });
  };

  const handleNewDisbursementSubmit = async (e) => {
    e.preventDefault();
    try {
      await FinancialService.addAgentDisbursement(newDisbursementData);
      onAddSuccess();
      setNewDisbursementData({
        agentid: "",
        matterid: "",
        description: "",
        fees: "",
        date_added: null,
      });
    } catch (err) {
      setError("Failed to add disbursement.");
    }
  };

  const handlePositiveNumberInput = (e) => {
    const value = e.target.value;
    setNewDisbursementData({
      ...newDisbursementData,
      fees: value && Math.abs(value) >= 0 ? Math.abs(value) : "",
    });
  };

  if (agentsLoading || loadingMatters) {
    return <div>Loading...</div>;
  }

  if (error || agentsError) {
    return <div>{error || agentsError}</div>;
  }

  const selectedAgent = agents.find(
    (option) => option.value === newDisbursementData.agentid
  );

  return (
    <div className="Fixed-fees-overlay">
      <div className="Fixed-fees-modal" ref={modalRef}>
        <div className="title-button-container">
          <h2>Add Disbursement</h2>
        </div>

        <form className="new-fee-form" onSubmit={handleNewDisbursementSubmit}>
          <div className="form-group">
            <label className="form-group-label">Agent</label>
            <Select
              options={agents}
              value={selectedAgent || null}
              onChange={handleSelectChange}
              placeholder="Select or search an agent..."
              isClearable
              isLoading={agentsLoading}
            />
          </div>
          <div className="Tasks-form-group">
            <label className="Tasks-form-group-label">Matter ID</label>
            <Select
              options={matters}
              onChange={handleMatterChange}
              isLoading={loadingMatters}
              placeholder="Select or search a matter..."
              isClearable
            />
          </div>
          <input
            type="text"
            placeholder="Description"
            value={newDisbursementData.description}
            onChange={(e) =>
              setNewDisbursementData({
                ...newDisbursementData,
                description: e.target.value,
              })
            }
            required
          />
          <input
            type="number"
            min="0"
            onInput={handlePositiveNumberInput}
            placeholder="Fees"
            value={newDisbursementData.fees}
            required
          />
          <input
            type="date"
            placeholder="Date Added (Optional)"
            value={newDisbursementData.date_added || ""}
            onChange={(e) =>
              setNewDisbursementData({
                ...newDisbursementData,
                date_added: e.target.value || null,
              })
            }
          />
          <button type="submit" className="submit-fee-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDisbursement;
