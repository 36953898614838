import { useState } from "react";
import TopBar from "./Components/TopBar";
import "./CreateAgentPage.css";

const CreateAgentPage = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Company: "",
    Email: "",
    Phone: "",
    Address: "",
    Jurisdiction: ""
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneKeyDown = (e) => {
    const key = e.key;
    // Allow backspace, delete, and arrow keys
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(key)) {
      return;
    }
  
    // Prevent the user from typing anything that's not a number
    if (!/^\d$/.test(key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="agents-Page">
      <TopBar formData={formData} setError={setError} setFormData={setFormData} setSuccess={setSuccess}></TopBar>
      <div className="agents-entire-form-container">
        <div className="agents-form-container">

        {success && <div className="success-message">{success}</div>}
        {error && <div className="error-message">{error}</div>}

          <div className="agents-form-group">
            <label className="agents-form-group-label">First name</label>
            <div className="agents-input-icon">
              <input
                type="text"
                name="FirstName"
                placeholder="Enter first name"
                value={formData.FirstName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Last name</label>
            <div className="agents-input-icon">
              <input
                type="text"
                name="LastName"
                placeholder="Enter last name"
                value={formData.LastName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Company</label>
            <div className="agents-input-icon">
              <input
                type="text"
                name="Company"
                placeholder="Enter company"
                value={formData.Company}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Email</label>
            <div className="agents-input-icon">
              <input
                type="email"
                name="Email"
                placeholder="Enter email"
                value={formData.Email}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Phone</label>
            <div className="agents-input-icon">
              <input
                type="tel"
                name="Phone"
                placeholder="Enter phone"
                value={formData.Phone}
                onChange={handleInputChange}
                onKeyDown={handlePhoneKeyDown}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // Example phone number format (XXX-XXX-XXXX)
                maxLength="14"
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Address</label>
            <div className="agents-input-icon">
              <input
                type="text"
                name="Address"
                placeholder="Enter address"
                value={formData.Address}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="agents-form-group">
            <label className="agents-form-group-label">Jurisdiction</label>
            <div className="agents-input-icon">
              <input
                type="text"
                name="Jurisdiction"
                placeholder="Enter Jurisdiction"
                value={formData.Jurisdiction}
                onChange={handleInputChange}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CreateAgentPage;
