import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BillableTable from './BillableTable/BillableTable';
import BillableCalender from './BillableCalender/BillableCalender';
import FinancialService from '../../../../AppSettings/services/FinancialService';
import './BillableTime.css';
import { useMsal } from "@azure/msal-react";
import EmployeeService from '../../../../AppSettings/services/EmployeeService';

const BillableTime = () => {
    const { accounts } = useMsal(); // Get logged-in user info
    const employeeid = accounts[0]?.idTokenClaims?.oid;
    const [employee, setEmployee] = useState([]);
    const { matterId } = useParams();
    const [billedTimeData, setBilledTimeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isTableView, setIsTableView] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);
    const [timeRecorded, setTimeRecorded] = useState('');
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [notes, setNotes] = useState('');

    // Fetch billed time data
    useEffect(() => {
        const fetchBilledTime = async () => {
        
            try {
                setIsLoading(true);
                const data = await FinancialService.getBilledTime(matterId);
                setBilledTimeData(data);
            } catch (error) {
                console.error("Error fetching billed time data:", error);
                setError("Failed to fetch billed time data.");
            } finally {
                setIsLoading(false);
            }
            
        };

        fetchBilledTime();
        getEmployeeById(employeeid);
    }, [matterId]);


    // Format time to HH:MM:SS
    const formatTimeRecorded = (time) => {
        const totalMinutes = parseFloat(time) * 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:00`;
    };

    // Calculate amount based on billed rate and time recorded
    const calculateAmount = (rate, time) => {
        // Ensure billedRate is a number
        const parsedRate = parseFloat(rate);
        if (isNaN(parsedRate)) {
            console.error('Invalid billedRate:', rate);
            return '0.00';
        }
    
        // Format time recorded and split into hours, minutes, and seconds
        const [hours, minutes, seconds] = time.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            console.error('Invalid timeRecorded format:', time);
            return '0.00';
        }
    
        const totalHours = hours + minutes / 60 + seconds / 3600;
        const amount = parsedRate * totalHours;
        return amount.toFixed(2);
    };

    const getEmployeeById = async (employeeid) => {
        try {
            const employee = await EmployeeService.getEmployeeById(employeeid);
            setEmployee(employee);
        } catch (error) {
            console.error("Error fetching employee:", error);
            setError("Failed to load employee.");
        }
    };

    // Add a new billed time entry
    const handleAddBilledTime = async (formData) => {
        try {
            const newEntry = await FinancialService.addBilledTime(formData);
            setBilledTimeData((prevData) => [...prevData, newEntry]);
        } catch (error) {
            console.error("Error adding billed time entry:", error);
            setError("Failed to add billed time entry.");
        }
    };

    // Update an existing billed time entry
    const handleUpdateBilledTime = async (btId, formData) => {
        try {
            const updatedEntry = await FinancialService.updateBilledTime(btId, formData);
            setBilledTimeData((prevData) =>
                prevData.map((entry) => (entry.bt_id === btId ? updatedEntry : entry))
            );
        } catch (error) {
            console.error("Error updating billed time entry:", error);
            setError("Failed to update billed time entry.");
        }
    };

    // Handle form submission for adding new billable time
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formattedTime = formatTimeRecorded(timeRecorded);
        const billedRate = (employee.billedrate);

        console.log('Employee Data:', employeeid); // Debug employee data
        console.log('Fetched billedRate:', billedRate); // Debug fetched billedRate
    
        const amount = calculateAmount(billedRate, formattedTime); // Calculate the amount
    
    
        const formData = {
            matterId,
            timeRecorded: formattedTime,
            date,
            notes,
            amount: amount,
            employeeid,
        };

        handleAddBilledTime(formData);
        setShowAddForm(false); // Close the form after submission
    };

    return (
        <div className="billable-container">
            <div className="billableTable-header">
                <h1>Billable Time</h1>
                <div className="billableTable-actions">
                    <button
                        className={`MF-toggle-view-button ${isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(true)}
                        aria-label="Table View"
                    >
                        <i className="fas fa-table"></i>
                    </button>
                    <button
                        className={`MF-toggle-view-button ${!isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(false)}
                        aria-label="Calendar View"
                    >
                        <i className="fas fa-calendar-alt"></i>
                    </button>
                    <button
                        className="MF-billableTable-add-button"
                        onClick={() => setShowAddForm(true)}
                    >
                        +
                    </button>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                isTableView ? (
                    <BillableTable
                        data={billedTimeData}
                        matterId={matterId}
                        onAddBilledTime={handleAddBilledTime}
                        onUpdateBilledTime={handleUpdateBilledTime}
                    />
                ) : (
                    <BillableCalender
                        data={billedTimeData}
                        matterId={matterId}
                        onAddBilledTime={handleAddBilledTime}
                        onUpdateBilledTime={handleUpdateBilledTime}
                    />
                )
            )}

            {showAddForm && (
                <div className="Billable-add-billable-time-popup">
                    <div className="Billable-popup-content">
                        <h2>Add Billable Time</h2>
                        <form onSubmit={handleFormSubmit}>
                            <label>
                                Time Recorded (Hours):
                                <input
                                    type="text"
                                    value={timeRecorded}
                                    onChange={(e) => setTimeRecorded(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Notes:
                                <textarea
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </label>
                            <button type="Billable-submit">Submit</button>
                            <button type="Billable-button" onClick={() => setShowAddForm(false)}>
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BillableTime;
