import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ClientMatters.css';
import getAuthHeaders from '../../../AppSettings/routes/functions/getAuthHeaders';

const ClientMatters = () => {
    const { clientId } = useParams();
    const [matters, setMatters] = useState([]);

    const fetchClientMatters = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clientmatters/${clientId}`, {
            headers: await getAuthHeaders()
          });
          if (response.ok) {
            const data = await response.json();
            
            // Sort matters by the numeric portion at the end of "matterid"
            const sortedData = data.sort((a, b) => {
              const numA = parseInt(a.matterid.split('-').pop(), 10);
              const numB = parseInt(b.matterid.split('-').pop(), 10);
              return numA - numB;
            });

            setMatters(sortedData);
          } else {
            throw new Error('Failed to fetch client matters');
          }
        } catch (error) {
          console.log('Error fetching client matters: ' + error);
        }
    };

    useEffect(() => {
        fetchClientMatters();
    }, [clientId]);

    return (
        <div>
            <h3 className="client-matters-title">Client Matters</h3>
            <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th>Matter</th>
                        <th>Leader</th>
                        <th>Status</th>
                        <th>Open Date</th>
                        <th>Close Date</th>
                    </tr>
                </thead>
                <tbody>
                    {matters.map((matter) => (
                        <tr key={matter.matterid}>
                            <td>
                                <Link to={`/matterDetails/${matter.matterid}`} className="ClientLink">
                                  {matter.matterid} {matter.mattername}
                                </Link>
                            </td>
          
                            <td>{matter.employeename}</td>
                            <td>{matter.status}</td>
                            <td>{matter.opendate}</td>
                            <td>{matter.closedate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default ClientMatters;
