import React, { useState, useEffect, useRef } from 'react';
import './TimerPopout.css';
import ReactSelect from 'react-select';
import getAuthHeaders from '../AppSettings/routes/functions/getAuthHeaders';
import { useMsal } from '@azure/msal-react';
import EmployeeService from '../AppSettings/services/EmployeeService';

const TimerPopout = ({ timer, onClose }) => {
  const { accounts } = useMsal();
  const [matters, setMatters] = useState([]);
  const [selectedMatter, setSelectedMatter] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [showPopout, setShowPopout] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const employeeid = accounts[0]?.idTokenClaims?.oid;
  const [employee, setEmployee] = useState({});
  const popoutRef = useRef(null);

  useEffect(() => {
    getEmployeeById(employeeid);
    fetchMatters();

    // Event listener for clicks outside the popout
    const handleClickOutside = (event) => {
      if (popoutRef.current && !popoutRef.current.contains(event.target)) {
        setShowPopout(false);
        onClose(); // Close the popout from the parent as well
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, employeeid]); 

  const getEmployeeById = async (employeeid) => {
    try {
      const employee = await EmployeeService.getEmployeeById(employeeid);
      setEmployee(employee);
    } catch (err) {
      console.error("Error fetching employee:", err);
    }
  };

  const fetchMatters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allmatters`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      const mattersData = Array.isArray(data) ? data : [];
      setMatters(mattersData);
    } catch (err) {
      console.error('Error fetching matters:', err);
    }
  };

  const handleMatterChange = (selectedOption) => {
    setSelectedMatter(selectedOption ? selectedOption.value : '');
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const formatTimeRecorded = (time) => time;

  const calculateAmount = (rate, time) => {
    const parsedRate = parseFloat(rate);
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const totalHours = hours + minutes / 60 + seconds / 3600;
    const amount = parsedRate * totalHours;
    return amount.toFixed(2);
  };

  const handleSubmitConfirmation = async () => {
    const formattedTime = formatTimeRecorded(timer);
    const billedRate = employee.billedrate;
    const amount = calculateAmount(billedRate, formattedTime);

    const submissionData = {
      matterId: selectedMatter,
      timeRecorded: formattedTime,
      date: date,
      notes: notes,
      employeeid: employeeid,
      amount: amount,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/billed_time`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(submissionData),
      });
      const responseBody = await response.json();
      console.log('Time entry submitted successfully:', responseBody);
      setSuccessMessage('Time entry submitted successfully.');
      setShowPopout(false);
    } catch (err) {
      console.error('Error submitting time entry:', err);
    }
  };

  const customFilterOption = (option, inputText) => {
    const words = inputText.toLowerCase().split(' ').filter((x) => x);
    const optionText = `${option.label} ${option.value}`.toLowerCase();
    return words.every((word) => optionText.includes(word));
  };

  return (
    <>
      {showPopout ? (
        <div className="timer-backdrop">
          <div className="timer-popout" ref={popoutRef}>
            <div className="timer-display">{timer}</div>
            <ReactSelect
              classNamePrefix="timer-select"
              className="timer-dropdown"
              value={
                selectedMatter
                  ? {
                      value: selectedMatter,
                      label: matters.find((m) => m.matterid === selectedMatter)
                        ? `${matters.find((m) => m.matterid === selectedMatter).matterid}: ${matters.find((m) => m.matterid === selectedMatter).mattername}`
                        : '',
                    }
                  : null
              }
              onChange={handleMatterChange}
              options={matters.map((matter) => ({
                value: matter.matterid,
                label: `${matter.matterid}: ${matter.mattername}`,
              }))}
              placeholder="Submit to Matter"
              isClearable={true}
              isSearchable={true}
              filterOption={customFilterOption}
            />
            <div className="timer-date-icon">
              <input type="date" value={date} onChange={handleDateChange} className="date-input" />
            </div>
            <textarea
              placeholder="Add Notes"
              value={notes}
              onChange={handleNotesChange}
              className="notes-input"
            />
            <button className="submit-button" onClick={handleSubmitConfirmation}>
              
            </button>
          </div>
        </div>
      ) : (
        <div className="success-message">
          {successMessage}
        </div>
      )}
    </>
  );
};

export default TimerPopout;
