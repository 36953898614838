import React from 'react';
import './DataBar.css'; // Updated CSS styles
import Total from './Components/Total/Total';
import PendingInvoices from './Components/PendingInvoices/PendingInvoices';

const DataBar = () => {
  return (
    <div className="data-bar">
      <div className="data-bar-item">
        <Total />
      </div>
      <div className="data-bar-item">
        <PendingInvoices />
      </div>
    </div>
  );
};

export default DataBar;
