import React, { useState, useEffect } from 'react';
import './Total.css';
import ClientService from '../../../../../AppSettings/services/ClientService';

const Total = () => {
    const [clientCount, setClientCount] = useState(0);

    const fetchClientCount = async () => {
        try {
            const response = await ClientService.getClientCount();  
            setClientCount(response);
        } catch (error) {
          console.log('Error fetching clients:', error);
        }
      };
    
      useEffect(() => {
        fetchClientCount();
      }, []);

return (
        <div className="total-container">
            <span className="total-count">{clientCount}</span>
            <span className="total-label">Total Clients</span>
        </div>
    );
};

export default Total;
