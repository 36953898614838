import "./BillableTime.css";
import GaugeChart from "react-gauge-chart";

function BillableTime({ title, percentage }) {
  return (
    <div className="billable-time-widget">
      <div className="billable-time-container">
        <GaugeChart
          id="gauge-chart"
          nrOfLevels={30}
          colors={["#000076", "#6464ed"]}
          arcWidth={0.8}
          percent={percentage}
          textComponent={<div className="bill-percentage">{percentage * 100} %</div>}
          style={{width: "15vw"}}
        />
      </div>
      <div className="billable-time-title">{title}</div>
    </div>
  );
}

export default BillableTime;
