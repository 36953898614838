import getAuthHeaders from "../routes/functions/getAuthHeaders";
const API_URL = process.env.REACT_APP_API_BASE_URL;

class MatterService {

  static async getMattersByEmployee(employeeId) {
    try {
      const response = await fetch(`${API_URL}/mymatters/${employeeId}`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      return data; // Return the matters assigned to the employee
    } catch (error) {
      console.error('Error fetching matters by employee ID:', error);
    }
  }

  static async getMatters() {
    const response = await fetch(`${API_URL}/allmatters`, {
      headers: await getAuthHeaders(),
    });
    const data = await response.json();
    const sortedMatters = data.sort((a, b) =>
      a.matterid.localeCompare(b.matterid)
    );
    return sortedMatters;
  }


  static async getMatterDetails(matterId) {
    try {
      const response = await fetch(`${API_URL}/matter_by_id/${matterId}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error fetching clients:', error);
    }
  }

  // Get mattername given a matter id
  static async getMatterName(matterId) {
    try {
      const response = await fetch(`${API_URL}/matter_name/${matterId}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error fetching clients:', error);
    }
  }

  

  // Add a matter
  static async addMatter(formData) {
    const response = await fetch(`${API_URL}/addmatter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...(await getAuthHeaders()),
      },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error("Error adding matter");
    }
    const data = await response.json();
    return data; // Return the response after adding the matter
  }

  static async getMatterStatusDistribution() {
        try {
            const response = await fetch(`${API_URL}/matter_status_distribution`, {
                headers: await getAuthHeaders(),
            });
            const data = await response.json();
            return data; // Return the data from the response
        } catch (error) {
            console.error('Error fetching matter status distribution:', error);
        }
    }
}

export default MatterService;
