import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import './InvoiceView.css'; // Assuming you'll add the styles in an external CSS file

function InvoiceView({ pdfUrl }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div className="invoice-container">
            <div className="invoice-card">
                <div className="invoice-header">
                    <div className="invoice-number">№ A01</div>
                    <div className="invoice-details">
                        <p>Payable <strong>$6,500.00</strong></p>
                        <p>Dues 15/08/2023</p>
                        <p>Issued 01/08/2023</p>
                        <p>Ref. #INV-057</p>
                    </div>
                    <div className="invoice-billed-to">
                        <p><strong>Billed to</strong></p>
                        <p>Company Name</p>
                        <p>Company address</p>
                        <p>City, Country - 00000</p>
                        <p>+0 (000) 123-4567</p>
                    </div>
                </div>
                <div className="invoice-items">
                    <p className="invoice-item-header">Responsive web design</p>
                    <p className="item-description">ITEM DESCRIPTION</p>
                    <p>Item Name</p>
                    <p>Item Name</p>
                    <p className="item-subtotal">Subtotal</p>
                    <p>Tax (10%)</p>
                </div>
                {pdfUrl ? (
                    <div className="pdf-viewer">
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <div className="pdf-navigation">
                            <button
                                disabled={pageNumber <= 1}
                                onClick={() => setPageNumber(pageNumber - 1)}
                            >
                                Previous
                            </button>
                            <span>
                                Page {pageNumber} of {numPages}
                            </span>
                            <button
                                disabled={pageNumber >= numPages}
                                onClick={() => setPageNumber(pageNumber + 1)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Loading PDF...</p>
                )}
            </div>
        </div>
    );
}

export default InvoiceView;
