// PendingItems.js
import React, { useState, useEffect } from 'react';
import './PendingItems.css';
import TaskService from '../../../AppSettings/services/TaskService';
import DeadlineChecklistService from '../../../AppSettings/services/DeadlineChecklistService';

const PendingItems = () => {

    const [counts, setCounts] = useState({
        incompleteDeadlines: 0,
        incompleteChecklistItems: 0,
        pendingTasks: 0,
        InprogressTasks: 0,
    });

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const deadlinesCount = await DeadlineChecklistService.getIncompleteDeadlinesCount();
                const checklistCount = await DeadlineChecklistService.getIncompleteChecklistItemsCount();
                const taskStatusData = await TaskService.getTaskStatusDistribution();

                // Extract pending tasks from the status distribution data
                const pendingTasks = taskStatusData.find(task => task.status === 'Pending')?.count || 0;
                const InprogressTasks = taskStatusData.find(task => task.status === 'In Progress')?.count || 0;

                setCounts({
                    incompleteDeadlines: deadlinesCount.incomplete_deadlines_count,
                    incompleteChecklistItems: checklistCount.incomplete_checklist_items_count,
                    pendingTasks,
                    InprogressTasks,
                });
            } catch (error) {
                console.error('Error fetching radar counts:', error);
            }
        };

        fetchCounts();
    }, []);

    const totalOnRadar = 
        counts.incompleteDeadlines + 
        counts.incompleteChecklistItems + 
        counts.pendingTasks;

    return (
        <div className="pending-items-container">
            <span className="pending-count">{totalOnRadar}</span>
            <span className="pending-label">Pending Items</span>
        </div>
    );
};

export default PendingItems;
