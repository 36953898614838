import { useState, useEffect } from "react";
import "./DisbursementPage.css";
import DisbursementTable from "./Components/DisbursementTable.js";
import FinancialService from "../../AppSettings/services/FinancialService.js";
import AddDisbursement from "./Components/AddDisbursement.js";

const GeneralDisbursmentsPage = () => {
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isAddDisbursementVisible, setIsAddDisbursementVisible] = useState(false); // State for popup visibility

  useEffect(() => {
    const fetchDisbursements = async () => {
      try {
        const disbursements = await FinancialService.getGeneralAgentDisbursements();
        const formattedData = disbursements.map(disbursement => ({
          agent: `${disbursement.firstname} ${disbursement.lastname}`,
          description: disbursement.description,
          fees: disbursement.fees,
          actions: disbursement.date_added,
        }));
        setFilteredTasks(formattedData);
      } catch (error) {
        console.error("Error fetching disbursements:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDisbursements();
  }, []);
  
  const handleAddDisbursementSuccess = () => {
    // Refresh the disbursement list after adding a new entry
    const fetchDisbursements = async () => {
      try {
        const disbursements = await FinancialService.getGeneralAgentDisbursements();
        const formattedData = disbursements.map(disbursement => ({
          agent: `${disbursement.firstname} ${disbursement.lastname}`,
          description: disbursement.description,
          fees: disbursement.fees,
          actions: disbursement.date_added,
        }));
        setFilteredTasks(formattedData);
      } catch (error) {
        console.error("Error fetching disbursements:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDisbursements();
  };


  const handleSearch = (search) => {
    const filtered = filteredTasks.filter(entry =>
      entry.agent.toLowerCase().includes(search.toLowerCase()) ||
      entry.description.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredTasks(filtered);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="DisbursementPage">
      <div className="Disbursement-header">
        <h1>Disbursements</h1>
        <button
          className="Disbursement-add-button"
          onClick={() => setIsAddDisbursementVisible(true)} // Show the AddDisbursement popup
        >
          +
        </button>
      </div>
      <DisbursementTable data={filteredTasks} />

      {/* Show AddDisbursement popup when the button is clicked */}
      {isAddDisbursementVisible && (
        <AddDisbursement
          onClose={() => setIsAddDisbursementVisible(false)} // Close the popup
          onAddSuccess={handleAddDisbursementSuccess} // Refresh the list after a successful add
        />
      )}
    </div>
  );
};

export default GeneralDisbursmentsPage;
