import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import getAuthHeaders from '../AppSettings/routes/functions/getAuthHeaders';
import './HomePage.css';
import DeadlineCount from './Components/DeadlineCount/DeadlineCount';
import MatterWidget from './Components/MatterStatus/MatterWidget';
import BilliableTarget from './Components/BilliableTarget/BilliableTarget';
import ClientCount from './Components/ClientCount/ClientCount';
import TaskWidget from './Components/Tasks/TaskWidget';
import RecentNotifications from './Components/RecentNotifications/RecentNotifications';
import PendingItems from './Components/PendingItems/PendingItems';

const HomePage = () => {
  const { accounts } = useMsal();

  useEffect(() => {
    const checkCurrentUserDetails = async () => {
      try {
        const account = accounts[0];
        const fullName = account.name;
        const email = account.username;
        const { oid } = account.idTokenClaims;

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check_user?cognitoSub=${oid}`, {
          method: 'GET',
          headers: await getAuthHeaders(),
        });
        const data = await response.json();
        if (!data.exists) {
          const [firstName, lastName] = fullName.split(' ');
          await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_employee`, {
            method: 'POST',
            headers: await getAuthHeaders(),
            body: JSON.stringify({
              employeeId: oid,
              email: email,
              firstName: firstName,
              lastName: lastName,
            }),
          });
        }
      } catch (error) {
        console.log('Error checking current user details:', error);
      }
    };

    checkCurrentUserDetails();
  }, [accounts]);

  return (
    <div className="HP-Container">
      <div className="HP-LeftSide">
      <DeadlineCount />
        <div className="HP-Row">
          <div className="Hp-MS">
            <MatterWidget />
          </div>
          <div className="HP-Bar-Graphs">
            <PendingItems />
            <BilliableTarget />
            <ClientCount />
          </div>
        </div>
      </div>
      <div className="HP-RightSide">
        <RecentNotifications />
        <TaskWidget />
      </div>
    </div>
  );
  
};

export default HomePage;
