import React from "react";
import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const CreateMatterDeadlineTopBar = ({ formData, handleDeadlineCreation }) => {
  const { matterId } = useParams();

  return (
    <div className="createMattersTopBar">
      <div id="leftSideCreateMattersTopBar">
        <Link to={`/matterDetails/${matterId}/tasks`}>
          <button className="mattersBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="mattersBackButton" />
          </button>
        </Link>
        <span>Create a Deadline</span>
      </div>
      <div id="rightSideCreateMattersTopBar">
        <button className="createMatterButton" onClick={handleDeadlineCreation}>
          Create Deadline
        </button>
      </div>
    </div>
  );
};

export default CreateMatterDeadlineTopBar;
