// import { LogLevel } from "@azure/msal-browser";
// // Browser check variables
// // If you support IE, our recommendation is that you sign-in using Redirect APIs
// // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent;
// const msie = ua.indexOf("MSIE ");
// const msie11 = ua.indexOf("Trident/");
// const msedge = ua.indexOf("Edge/");
// const firefox = ua.indexOf("Firefox");
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// /**
//  * Enter here the user flows and custom policies for your B2C application
//  * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
//  * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
//  */
// const b2cEnvConfigs = process.env;

// export const b2cPolicies = {
//   names: {
//     signUpSignIn: b2cEnvConfigs.REACT_APP_B2C_SIGNIN_POLICY,
//     // editProfile: "B2C_1_ProfileEditPolicy",
//   },
//   authorities: {
//     signUpSignIn: {
//       authority: b2cEnvConfigs.REACT_APP_B2C_SIGNIN_AUTHORITY,
//     },
//     // editProfile: {
//     //   authority:
//     //     "https://testorg007001.b2clogin.com/testorg007001.onmicrosoft.com/B2C_1_ProfileEditPolicy",
//     // },
//   },
//   authorityDomain: b2cEnvConfigs.REACT_APP_B2C_AUTHORITY_DOMAIN,
// };

// // Config object to be passed to Msal on creation
// export const msalConfig = {
//   auth: {
//     clientId: b2cEnvConfigs.REACT_APP_B2C_CLIENT_ID,
//     authority: b2cPolicies.authorities.signUpSignIn.authority,
//     knownAuthorities: [b2cPolicies.authorityDomain],
//     redirectUri: b2cEnvConfigs.REACT_APP_B2C_REDIRECT_URI,
//     postLogoutRedirectUri: b2cEnvConfigs.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
//     // replyUrlsWithType: [
//     //   {
//     //     url: "http://localhost:3000/signin-callback",
//     //     type: "Spa",
//     //   },
//     // ],
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: isIE || isEdge || isFirefox,
//   },
//   system: {
//     allowNativeBroker: false, // Disables WAM Broker
//     loggerOptions: {
//       loggerCallback: (level, message, containsPii) => {
//         if (containsPii) {
//           return;
//         }
//         switch (level) {
//           case LogLevel.Error:
//             console.error(message);
//             return;
//           case LogLevel.Info:
//             console.info(message);
//             return;
//           case LogLevel.Verbose:
//             console.debug(message);
//             return;
//           case LogLevel.Warning:
//             console.warn(message);
//             return;
//           default:
//             return;
//         }
//       },
//     },
//   },
// };

// // Scopes you add here will be prompted for consent during login
// export const loginRequest = {
//   scopes: [b2cEnvConfigs.REACT_APP_B2C_SCOPE],
// };

// /**
//  * Enter here the coordinates of your web API and scopes for access token request
//  * The current application coordinates were pre-registered in a B2C tenant.
//  */
// export const apiConfig = {
//   scopes: [b2cEnvConfigs.REACT_APP_B2C_SCOPE],
//   uri: b2cEnvConfigs.REACT_APP_B2C_API_CONFIG_URL,
// };

// import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    // clientId: "c413d527-001b-4904-8a65-a92e18d668e0",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    // authority: `https://login.microsoftonline.com/b45cbfd5-3765-4b68-9ef9-4a363e8c0894`,
    redirectUri: "/", //eg: ${window.location.origin}/Dashboard
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // "sessionStorage" or"localStorage"
    storeAuthStateInCookie: false,
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //       default:
    //         return;
    //     }
    //   },
    // },
    allowNativeBroker: false,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
  scopes: ["User.Read", "Files.Read"],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net",
};