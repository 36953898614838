import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Removed unnecessary Router import
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate,} from "@azure/msal-react";
import { ClientSideNavigation } from "./client-side-navigation.js";
import { QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import NavBar from '../../navbar/NavBar.js';
import HomePage from '../../Home/HomePage.js';
import Timer from '../../Timer/Timer.js';
import TasksPage from '../../Tasks/TasksPage.js';
import GeneralMatters from '../../Matters/MatterDetails/GeneralMatters/GeneralMatters.js';
import ClientsPage from '../../Clients/ClientsPage.js';
import AgentsPage from '../../Agents/AgentsPage.js';
import NotificationsPage from '../../Notifications/NotificationsPage.js';
import CreateClientPage from '../../Clients/CreateClient/CreateClientPage.js';
import MattersPage from '../../Matters/MattersPage.js';
import CreateMattersPage from '../../Matters/CreateMatters/CreateMattersPage.js';
import Files from '../../Matters/MatterDetails/Files/Files.js';
import FinancialPage from '../../Matters/MatterDetails/Financial/FinancialPage.js';
import FinancialsPage from '../../Financials/FinancialsPage.js';
import DisbursementPage from '../../Matters/MatterDetails/Financial/Disbursements/DisbursementPage.js';
import MatterDetails from '../../Matters/MatterDetails/MatterDetails.js';
import CreateTasksPage from '../../Tasks/Tasks/CreateTasks/CreateTasksPage.js';
import InvoicePage from '../../Matters/MatterDetails/Financial/Invoice/InvoicePage.js';
import FixedFees from '../../Matters/MatterDetails/Financial/FixedFees/FixedFees.js';
import DisbursementTable from '../../Matters/MatterDetails/Financial/Disbursements/DisbursementPage.js';
import BillableTime from '../../Matters/MatterDetails/Financial/BillableTime/BillableTime.js';
import MatterTasksPage from '../../Matters/MatterDetails/MatterTasks/MatterTasksPage.js';
import MatterCreateTasksPage from '../../Matters/MatterDetails/MatterTasks/MatterTasks/CreateTasks/MatterCreateTasksPage.js';
import Profile from '../../Profile/ProfilePage.js';
import CreateDeadlinePage from '../../Tasks/Deadline/CreateDeadline/CreateDeadlinePage.js';
import CreateAgentPage from '../../Agents/CreateAgent/CreateAgentPage.js';
import ClientDetails from '../../Clients/ClientDetails/ClientDetails.js';
import CreateChecklistPage from '../../Tasks/Checklist/CreateChecklist/CreateChecklistsPage.js';
import CreateMatterDeadlinePage from '../../Matters/MatterDetails/MatterTasks/MatterDeadline/CreateDeadline/CreateMatterDeadlinePage.js';
import CreateMatterChecklistPage from '../../Matters/MatterDetails/MatterTasks/MatterChecklist/CreateChecklist/CreateMatterChecklistPage.js';
import AdditionalNotes from '../../Matters/MatterDetails/AdditionalNotes/AdditonalNotes.js';
const queryClient = new QueryClient();

const App = ({ pca }) => {

  return (
    <QueryClientProvider client={queryClient}>
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <nav> 
          <NavBar/>
        </nav>
        <AuthenticatedTemplate>
          <div>
            <Routes>
              <Route path="/" element={<HomePage />}/>
              <Route path="/matters" element={<MattersPage />}></Route>
              <Route path="/createMatters" element={<CreateMattersPage />}></Route>
              <Route path="/matterDetails/:matterId" element={<MatterDetails />}></Route>
              <Route path="/matterDetails/:matterId/files" element={<Files />}></Route>
              <Route path="/matterDetails/:matterId/tasks" element={<MatterTasksPage />}></Route>
              <Route path="/matterDetails/:matterId/tasks/createTasks" element={<MatterCreateTasksPage />}></Route>
              <Route path="/matterDetails/:matterId/tasks/createDeadline" element={<CreateMatterDeadlinePage />}></Route>
              <Route path="/matterDetails/:matterId/tasks/createChecklist" element={<CreateMatterChecklistPage />}></Route>
              <Route path="/matterDetails/:matterId/financial" element={<FinancialPage />}></Route>
              <Route path="/matterDetails/:matterId/financial/invoice" element={<InvoicePage />}></Route>
              <Route path="/matterDetails/:matterId/financial/fixedFees" element={<FixedFees />}></Route>
              <Route path="/matterDetails/:matterId/financial/disbursements" element={<DisbursementTable />}></Route>
              <Route path="/matterDetails/:matterId/financial/billableTime" element={<BillableTime />}></Route>
              <Route path="/matterDetails/:matterId/disbursements" element={<DisbursementPage />}></Route>
              <Route path="/matterDetails/:matterId/additionalNotes" element={<AdditionalNotes />}></Route>
              <Route path="/tasks" element={<TasksPage />}/>
              <Route path="/generalmatters" element={<GeneralMatters />}/>
              <Route path="/clients" element={<ClientsPage />}/>
              <Route path="/clients/:clientId" element={<ClientDetails />}/>
              <Route path="/agents" element={<AgentsPage />}/>
              <Route path="/notifications" element={<NotificationsPage />}/>
              <Route path="/Timer" element={<Timer />}/>
              <Route path="/createTasks" element={<CreateTasksPage />}/>
              <Route path="/createDeadline" element={<CreateDeadlinePage />}/>
              <Route path="/createChecklist" element={<CreateChecklistPage />}/>
              <Route path="/createClient" element={<CreateClientPage />}/>
              <Route path="/createAgent" element={<CreateAgentPage />}/>
              <Route path="/financials" element={<FinancialsPage/>}></Route>
              <Route path="/profile" element={<Profile />}/>
              {/* <Route path="/matterDetails" element={<MatterDetails />}/> */}
        
            </Routes>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          SEPT Software
        </UnauthenticatedTemplate>
      </MsalProvider>
    </ClientSideNavigation>
    </QueryClientProvider>
  );
};

export default App;