import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaClock, FaBell } from 'react-icons/fa';
import './NavBar.css';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Timer from '../Timer/Timer';

function NavBarAuth() {
  const { instance, accounts } = useMsal();
  const [userName, setUserName] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [showTimer, setShowTimer] = useState(false);

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const toggleTimer = () => {
    setShowTimer(!showTimer);
  };

  useEffect(() => {
    if (instance && accounts.length > 0) {
      const request = {
        scopes: ["user.read.all"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then((response) => {
              setAccessToken(response.accessToken);
            });
          }
        });
    }
  }, [instance, accounts]);

  useEffect(() => {
    if (instance) {
      setUserName(instance.getActiveAccount().idTokenClaims.name);
    }
  }, [instance]);

  return (
    <div className="NavBar">
      <div className="navbar-container">
        <div className="navbar-SEPT">
          <Link to="/" className="SEPT"><strong>SEPT.</strong></Link>
        </div>
        <div className="navbar-pages">
          <Link to="/matters">Matters</Link>
          <Link to="/tasks">Tasks</Link>
          <Link to="/clients">Clients</Link>
          <Link to="/agents">Agents</Link>
          <Link to="/financials">Financials</Link>
        </div>
        <div className="navbar-icons">
          <Link onClick={toggleTimer}><FaClock size={24} className="icon" /></Link>
          <Link to="/notifications"><FaBell size={24} className="icon" /></Link>
          <Link to="/Profile"><FaUserCircle size={24} className="icon" /></Link>
        </div>
      </div>
      {showTimer && <Timer />} 
    </div>
  );
}

export default NavBarAuth;
