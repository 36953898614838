import React, { useState, useEffect } from 'react';
import './TaskSideBar.css';
import TaskProgressbar from './ProgressBar/TaskProgressbar';


const TaskSideBar = ({ task, onClose, isOpen }) => {
  const [status, setStatus] = useState(task ? task.status : '');
  const [showAuditTrail, setShowAuditTrail] = useState(false); // New state for AuditTrail modal

  useEffect(() => {
    if (task) {
      setStatus(task.status);
    }
  }, [task]);

  if (!task) return null;

  const getProgress = (status) => {
    switch (status) {
      case 'Pending':
        return 0;
      case 'In Progress':
        return 50;
      case 'Completed':
        return 100;
      default:
        return 0;
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    if (task) task.status = newStatus;
  };

  const toggleAuditTrail = () => setShowAuditTrail(!showAuditTrail); // Toggle modal visibility

  return (
    <div className={`Task-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="Task-header">
        <h2>Task Details</h2>

        <button className="Task-close-btn" onClick={onClose}>✕</button>
      </div>

      <div className="Task-Sidebar-contents">
        <div className="Task-details-container">
          <div className="Task-title">
            <h3>{task.task_title}</h3>
          </div>
          
          <div className="Task-meta">
            <p><strong>Due</strong> - <span>{task.duedate}</span></p>
            <p><strong>Assigned To</strong> - <span>{task.assignedto}</span></p>
          </div>
        </div>

        <div className="Task-progress-bar-container">
          <TaskProgressbar 
            progress={getProgress(status)} 
            taskId={task.taskid} 
            currentStatus={status}
            onStatusChange={handleStatusChange} 
          />
        </div>

        <div className="Task-info">
          <div className="Task-info-row">
            <p><strong>Status -</strong> {status}</p>
            <p><strong>ID -</strong> {task.taskid}</p>
          </div>
          <p><strong>Description -</strong> {task.description}</p>
        </div>

        <div className="Task-associated-documents">
          <h4>Associated Documents</h4>
          {/* Add document-related content here */}
        </div>
      </div>

    </div>
  );
};

export default TaskSideBar;
