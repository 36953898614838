import getAuthHeaders from '../routes/functions/getAuthHeaders';
const API_URL = process.env.REACT_APP_API_BASE_URL; 

class EmployeeService {
    // Get all clients 
    static async getEmployees() {
        try {
            const response = await fetch(`${API_URL}/employees`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            const sortedClients = data.sort((a, b) => a.EmployeeID.localeCompare(b.EmployeeID));
            return sortedClients;
          } catch (error) {
            console.log('Error fetching clients:', error);
          }
    }

    // Get employee based on Employee ID
    static async getEmployeeById(employeeId) {
        try {
            const response = await fetch(`${API_URL}/getemployee/${employeeId}`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            return data;
          } catch (error) {
            console.log('Error fetching employee:', error);
          }
    }

 
    // Update billed rate based on Employee ID
    static async updateBilledRate(employeeId, newBilledRate) {
      try {
        const response = await fetch(`${API_URL}/employee/${employeeId}`, {
          method: 'PUT',
          headers: await getAuthHeaders(),
          body: JSON.stringify({ billedrate: newBilledRate })
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.log('Error updating billed rate:', error);
      }
    }

}

export default EmployeeService;
