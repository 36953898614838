import "./MattersTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const MattersTable = ({ data, showLeader }) => {
  if (!data) {
    return <></>;
  }

  if (data.length === 0) {
    return <h3 className="no-matters">No matters found</h3>;
  }


  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Matter</th>
       
            <th>Status</th>
            <th>Client</th>
            {showLeader && <th>Leader</th>} {/* Conditionally render Leader */}
            <th>Open Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                <Link to={`/matterDetails/${row.matterid}`} className="matterLink">
                  {row.matterid} {row.mattername}
                </Link>
              </td>
     
              <td>{row.status}</td>
              <td>
                <div className="d-flex">
                  <FontAwesomeIcon icon={faUser} className="tableIcon" />
                  <span>{row.clientname}</span>
                </div>
              </td>
              {showLeader && (
                <td>
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faUser} className="tableIcon" />
                    <span>{row.employeename}</span>
                  </div>
                </td>
              )}
              <td>{row.opendate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MattersTable;
