import React from 'react';
import ClientInfo from './Components/ClientInfo/ClientInfo';
import Calendar from  './Components/Calendar/Calendar'
import TaskTable from './Components/TasksTable/TaskTable';
import MatterInfo from './Components/MatterInfo/MatterInfo';
import DCTable from './Components/DCTable/DCTable';
import './GeneralMatters.css';

function GeneralMatters() {
   

    return (
  <div className="GM-Container ">
    <div className="GM-LeftSide">
        <div className="GM-Row">
            <div className="GM-MI">
            <MatterInfo />
            </div>  
            <div className="GM-CI">
            <ClientInfo />
            </div>
            </div>
            <TaskTable />
            </div>
            <div className="GM-RightSide">
            <Calendar />
            <DCTable />
         </div>
        </div>
    );
}

export default GeneralMatters;