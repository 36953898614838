import { useState } from "react";
import "./TaskTable.css";
import MDTasksTable from "./Components/TaskData/MDTaskTable.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faDatabase } from "@fortawesome/free-solid-svg-icons";
import MetaData from "./Components/MetaData/MetaData";

const TaskTable = () => {
  const [view, setView] = useState("data"); // "table" or "data"

  const toggleView = (selectedView) => {
    setView(selectedView);
  };

  return (
    <div className="MDtasksPage">
      <div className="MD-Task-iconContainer">
      <FontAwesomeIcon
          icon={faDatabase}
          className={`MD-Task-icon ${view === "data" ? "active" : ""}`}
          onClick={() => toggleView("data")}
        />
        <FontAwesomeIcon
          icon={faTable}
          className={`MD-Task-icon ${view === "table" ? "active" : ""}`}
          onClick={() => toggleView("table")}
        />
      </div>
      {view === "table" ? <MDTasksTable /> : <MetaData />}
    </div>
  );
};

export default TaskTable;
