import { useState } from "react";
import FinancialDetailsTopBar from "./Components/TopBar/TopBar";
import "./FinancialPage.css";
import FinancialDashboard from "./FinancialDashboard/FinancialDashboard";
import FixedFees from "./FixedFees/FixedFees";
import DisbursmentsPage from "./Disbursements/DisbursementPage";
import BillableTime from "./BillableTime/BillableTime";
import InvoicePage from "./Invoice/InvoicePage";

const FinancialPage = () => {
  const [activePage, setActivePage] = useState("dashboard"); // State to track the active section

  const renderActivePage = () => {
    switch (activePage) {
      case "dashboard":
        return <FinancialDashboard />;
      case "invoice":
        return <InvoicePage />;
      case "fixedFees":
        return <FixedFees />;
      case "disbursements":
        return <DisbursmentsPage />;
      case "billableTime":
        return <BillableTime />;
      default:
        return <FinancialDashboard />;
    }
  };

  return (
    <div className="financial-details-page">
      <FinancialDetailsTopBar setActivePage={setActivePage} /> {/* Pass setActivePage */}

      <div>
      <div className="matter-content">{renderActivePage()}</div>
       </div> {/* Render the selected section */}
    </div>
  );
};

export default FinancialPage;
