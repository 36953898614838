import React, { useEffect, useState } from 'react';
import './Completed.css';
import TaskService from '../../../../../../../../AppSettings/services/TaskService';
import { useParams } from "react-router-dom";

const Completed = () => {
    const { matterId } = useParams();
    const [completedCount, setCompletedCount] = useState(0); // State to hold the completed count

    useEffect(() => {
        const fetchCompletedTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistributionByMatterId(matterId);
                
                // Find the task with status 'Completed' and get its count
                const completedTask = data.find(task => task.status === 'Completed');
                const count = completedTask ? completedTask.count : 0;

                setCompletedCount(count); // Update the state with completed task count
            } catch (error) {
                console.error('Error fetching completed tasks:', error);
            }
        };

        fetchCompletedTasks(); // Fetch the data when the component mounts
    }, [matterId]);

    return (
        <div className="Completed-container">
            <span className="Completed-count">{completedCount}</span>
            <span className="Completed-label">Completed</span>
        </div>
    );
};

export default Completed;
