import React, { useEffect, useRef } from 'react';
import './PieChart.css';
import { Chart } from 'chart.js/auto';

function PieChart({title, pieData}) {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        new Chart(ctx, {
            type: 'pie',
            data: {
                labels: pieData.labels,
                datasets: [
                    {
                        data: pieData.data, 
                        backgroundColor: ['#4b72b0', '#6ba8ff', '#bfc7ff', '#9f76e5', '#ff78cb'],
                        hoverOffset: 4,
                        borderWidth: 0 
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false, // Allows direct control over size
                plugins: {
                    legend: {
                        display: false, // Hides the default legend
                    }
                }
            }
        });
    }, [pieData.data, pieData.labels]);

    return (
        <div className="piechart-status-widget">
            <div className="piechart-chart-container">
                <canvas className="piechart-status-chart" ref={chartRef}></canvas>
            </div>
            <div className="piechart-title">{title}</div>
        </div>
    );
}

export default PieChart;
