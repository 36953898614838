import Calendar from "../../Matters/MatterDetails/GeneralMatters/Components/Calendar/Calendar";
import "./FinancialDashboard.css";
import MatterStatus from "./MatterStatus/MatterStatus";
import Disbursements from "./Disbursements/Disbursements";
import InvoiceWidget from "./Invoice/InvoiceWidget";
import BillableTime from "./BillableTime/BillableTime";
import FinancialGraph from "./FinancialGraph/FinancialGraph";

// Sample data for charts ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const lineData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Transactions",
      data: [12, 19, 3, 5, 2, 3],
      fill: false,
      backgroundColor: "rgb(75, 192, 192)",
      borderColor: "rgba(75, 192, 192, 1)",
    },
    {
      label: "Expenses",
      data: [2, 3, 20, 5, 1, 4],
      fill: false,
      backgroundColor: "rgb(153, 102, 255)",
      borderColor: "rgba(153, 102, 255, 1)",
    },
  ],
};

const pieData = {
  labels: ['Open', 'Pending', 'Closed', 'In Progress', 'On Hold'],
  data: [20, 15, 25, 30, 10],
  backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
};

const invoiceData = {
  sent: 12,
  unpaid: 20,
  paid: 19,
};

const BILLABLETIMEPERCENTAGE = 0.37;

const FinancialDashboard = () => {
  return (
    <div>
      <div className="financial-details-grid">
        <div className="financial-dashboard-top-section">
          {/* Financial Overview */}
          <FinancialGraph lineData={lineData}></FinancialGraph>
          {/* Calendar - Using same calendar as one in general matters*/}
          <div className="financial-dashboard-calendar">
            <Calendar/>
          </div>
        </div>
      </div>
      <div className="financial-dashboard-bottom-section">
        {/* Billable Target */}
        <div>
          <BillableTime title={"Billable Target"} percentage={BILLABLETIMEPERCENTAGE}></BillableTime>
        </div>

        {/* Disbursements */}
        <div>
          <Disbursements pieData={pieData}/>
        </div>

        {/* Matter Status */}
        <div>
            <MatterStatus pieData={pieData}/>
        </div>

        {/* Invoices */}
        <div>
          <InvoiceWidget invoiceData={invoiceData}></InvoiceWidget>
        </div>
      </div>
    </div>
  );
};

export default FinancialDashboard;
