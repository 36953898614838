import React from 'react';
import './RecentActivites.css';

const RecentActivities = () => {
    return (
        <div className="recent-activities">
       
            <ul className="activities-list">
                <li className="activity-item">
                    <span className="activity-id">S0015-S0034</span> Land Negotiation - Contract Updates Due in 4 days
                </li>
                <li className="activity-item">
                    <span className="activity-id">S0015-S0034</span> Wrongful Termination - Client needs Updates on Negotiation minutes
                </li>
                <li className="activity-item">
                    <span className="activity-id">S0015-S0034</span> Land Negotiation - Matter was created
                </li>
            </ul>
        </div>
    );
};

export default RecentActivities;
