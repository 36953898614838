import { useState, useEffect } from "react";
import { useMsal } from '@azure/msal-react';
import TopBar from "./Components/TopBar";
import Select from "react-select";
import MatterService from "../../../AppSettings/services/MatterService";
import EmployeeService from "../../../AppSettings/services/EmployeeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./CreateDeadlinePage.css";
import DeadlineChecklistService from "../../../AppSettings/services/DeadlineChecklistService";
const CreateDeadlinePage = () => {
    const { accounts } = useMsal();
    const { oid } = accounts[0].idTokenClaims;
    const [formData, setFormData] = useState({
      deadline_type: "",
      type: "",
      due_date: "",
      matterid: "",
      status: false, // Default to 'false' for 'Pending'
      internal_external: "",
    });
  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [matters, setMatters] = useState([]);
    const [loadingMatters, setLoadingMatters] = useState(true);
  
    useEffect(() => {
      const fetchMatters = async () => {
        try {
          const data = await MatterService.getMatters();
          const options = data.map((matter) => ({
            value: matter.matterid,
            label: `${matter.matterid}: ${matter.mattername}`,
          }));
          setMatters(options);
        } catch (error) {
          console.error("Error fetching matters:", error);
          setError("Failed to load matters.");
        } finally {
          setLoadingMatters(false);
        }
      };
  
      fetchMatters();
    }, []);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleMatterChange = (selectedOption) => {
      setFormData({ ...formData, matterid: selectedOption ? selectedOption.value : "" });
    };
  
    const handleDeadlineCreation = async () => {
      setLoading(true);
      setError("");
  
      try {
        const response = await DeadlineChecklistService.addDeadline(formData);
        if (response.error) throw new Error(response.error);
        alert("Deadline created successfully!");
        setFormData({
          deadline_type: "",
          type: "",
          due_date: "",
          matterid: "",
          status: false,
          internal_external: "",
        });
      } catch (error) {
        console.error("Error creating deadline:", error);
        setError("Failed to create the deadline. Please try again.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="Deadline-Page">
        {/* Pass the handleDeadlineCreation to CreateDeadlineTopBar */}
        <TopBar
          formData={formData}
          handleDeadlineCreation={handleDeadlineCreation}
        />
  
        <div className="Deadline-form-container">
          <div className="Deadline-form-group">
            <label className="Deadline-form-group-label">Deadline Type</label>
            <input
              type="text"
              name="deadline_type"
              placeholder="Enter deadline type"
              value={formData.deadline_type}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="Deadline-form-group">
            <label className="Deadline-form-group-label">Type</label>
            <input
              type="text"
              name="type"
              placeholder="Enter type"
              value={formData.type}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="Deadline-form-group">
            <label className="Deadline-form-group-label">Due Date</label>
            <input
              type="date"
              name="due_date"
              value={formData.due_date}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="Deadline-form-group">
            <label className="Tasks-form-group-label">Matter ID</label>
            <Select
              options={matters}
              onChange={handleMatterChange}
              isLoading={loadingMatters}
              placeholder="Select or search a matter..."
              isClearable
            />
          </div>
  
          <div className="Deadline-form-group">
            <label className="Deadline-form-group-label">Internal or External</label>
            <input
              type="text"
              name="internal_external"
              placeholder="Enter 'internal' or 'external'"
              value={formData.internal_external}
              onChange={handleInputChange}
            />
          </div>
  
          {error && <div className="error-message">{error}</div>}
          {loading && <div className="loading-message">Creating deadline...</div>}
        </div>
      </div>
    );
  };
  
  export default CreateDeadlinePage;