import IconButton from "./Component/IconButton";
import "./TopBar.css";
import "./Component/IconButton";
import { faUser, faUserFriends, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import SearchBar from "./Component/SearchBar";

const TopBar = ({ onSearch }) => {
  return (
    <div className="notificationrightSidetopBar">
      
        <IconButton icon={faUpload} ariaLabel="User" />
        <SearchBar onSearch={onSearch}></SearchBar>
  
    </div>
  );
};

export default TopBar;
