import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./MDTaskTable.css";
import TaskService from "../../../../../../../AppSettings/services/TaskService";
import { useParams } from "react-router-dom";

const MDTasksTable = () => {
  const { matterId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);

  // Fetch tasks on component mount
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const data = await TaskService.getTasksByMatterId(matterId);
        setTasks(data);
        setFilteredTasks(data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    if (matterId) {
      fetchTasks();
    }
  }, [matterId]);

  return (
    <div className="MD-Task-table-container">
      <table className="MD-Task-table">
        <thead>
          <tr>
            <th>Task</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredTasks.slice(0, 4).map((row, index) => (
            <tr key={index}>
              <td>
                <button className="MD-Task-Link">{row.task_title}</button>
              </td>
              <td>{row.status}</td>
              <td>
                <div className="MD-Task-d-flex">
                  <FontAwesomeIcon icon={faUser} className="MD-Task-tableIcon" />
                  <span>{row.assignedto}</span>
                </div>
              </td>
              <td>{row.duedate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MDTasksTable;
