import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import MatterService from "../../../../AppSettings/services/MatterService";

const CreateMattersTopBar = ({ formData }) => {
  const mutation = useMutation({
    mutationFn: () => MatterService.addMatter(formData),
    onSuccess: (data) => {
      // Handle the successful mutation (e.g., show a success message or refetch data)
      console.log("Matter created successfully:", data);
    },
    onError: (error) => {
      // Handle the error (e.g., show an error message)
      console.error("Error creating matter:", error);
    },
  })

  const handleSubmit = () => {
    console.log(formData);
    // Trigger the mutation with data
    mutation.mutate(formData);
  }

  return (
    <div className="createMattersTopBar">
      <div id="leftSideCreateMattersTopBar">
        <Link to="/matters">
          <button className="mattersBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="mattersBackButton" />
          </button>
        </Link>
        <span>Create a Matter</span>
      </div>
      <div id="rightSideCreateMattersTopBar">
        <button className="createMatterButton" onClick={handleSubmit}>Create Matter</button>
      </div>
    </div>
  );
};

export default CreateMattersTopBar;
