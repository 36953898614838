import React from 'react';
import './TaskProgressBar.css';
import TaskService from '../../../../../../AppSettings/services/TaskService';

const TaskProgressbar = ({ progress, taskId, currentStatus, onStatusChange }) => {
    const statuses = ["Pending", "In Progress", "Completed"];
    const currentIndex = statuses.indexOf(currentStatus);

    const handleStatusChange = async (direction) => {
        const newIndex = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;
        if (newIndex < 0 || newIndex >= statuses.length) return;

        const newStatus = statuses[newIndex];
        try {
            let response;
            if (newStatus === 'Pending') {
                response = await TaskService.updateTaskStatusToPending(taskId);
            } else if (newStatus === 'In Progress') {
                response = await TaskService.updateTaskStatusToInProgress(taskId);
            } else if (newStatus === 'Completed') {
                response = await TaskService.updateTaskStatusToComplete(taskId);
            }

            if (!response.error) {
                onStatusChange(newStatus);
            } else {
                console.error(`Error updating task status to ${newStatus}:`, response.error);
                alert(`Failed to update task status: ${response.error.message}`);
            }
        } catch (error) {
            console.error(`Unexpected error updating task status to ${newStatus}:`, error);
            alert(`Unexpected error updating task status: ${error.message}`);
        }
    };

    return (
        <div className="Task-progress-bar-container">
            <div className="progress-buttons">
                <button
                    className="progress-button"
                    onClick={() => handleStatusChange('prev')}
                    disabled={currentIndex === 0}
                >
                    &laquo;
                </button>
                <button
                    className="progress-button"
                    onClick={() => handleStatusChange('next')}
                    disabled={currentIndex === statuses.length - 1}
                >
                    &raquo;
                </button>
            </div>

            <div className="Task-progress-bar">
                <div 
                    className="Task-progress-fill" 
                    style={{ width: `${progress}%`, transition: 'width 0.4s ease-in-out' }}
                ></div>
            </div>
        </div>
    );
};

export default TaskProgressbar;
