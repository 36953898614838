
import { Link } from "react-router-dom";
import "./ClientsTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const ClientsTable = ({ data }) => {
  if (!data) {
    return <></>
  }

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Client</th>
            <th>Company</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              
              <td>
              <Link to={`/clients/${row.ClientID}`} className="ClientLink">
             {row.ClientID}
              </Link>
              </td>
              <td>
                <div className="d-flex">
                    <FontAwesomeIcon icon={faUser} className="tableIcon" />
                    <span>{`${row.FirstName} ${row.LastName}`}</span>
                </div>        
             </td>
              <td>{row.Company}</td>
              <td>{row.Email}</td>
              <td>{row.Phone}</td>
              <td>{row.Address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsTable;
