import getAuthHeaders from '../routes/functions/getAuthHeaders';
const API_URL = process.env.REACT_APP_API_BASE_URL;

class FinancialService {
    static async getAssignedFixedFees(matterId) {
        try {
            const response = await fetch(`${API_URL}/assigned_fixed_fees/${matterId}`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching assigned fixed fees:', error);
        }
    }

    static async getFixedFees() {
        try {
            const response = await fetch(`${API_URL}/fixed_fees`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching fixed fees:', error);
        }
    }

    static async addAssignedFixedFees(formData) {
        try {
            const response = await fetch(`${API_URL}/assigned_fixed_fees`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(await getAuthHeaders()),
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error adding assigned fixed fees:', error);
        }
    }

    static async addFixedFee(formData) {
        try {
            const response = await fetch(`${API_URL}/add_fixed_fees`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(await getAuthHeaders()),
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error adding fixed fee:', error);
        }
    }

    static async getBilledTime(matterId) {
        try {
            const response = await fetch(`${API_URL}/get_billed_time/${matterId}`, {
                headers: await getAuthHeaders(),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching billed time data:', error);
        }
    }

    static async getAllBilledTime() {
        try {
            const response = await fetch(`${API_URL}/get_all_billed_time`, {
                headers: await getAuthHeaders(),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching all billed time data:', error);
        }
    }
    
    static async addBilledTime(formData) {
        try {
            const response = await fetch(`${API_URL}/billed_time`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(await getAuthHeaders()),
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error adding billed time entry:', error);
        }
    }

    // New method to update a billed time entry
    static async updateBilledTime(btId, formData) {
        try {
            const response = await fetch(`${API_URL}/edit_billed_time/${btId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    ...(await getAuthHeaders()),
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error updating billed time entry:', error);
        }
    }
    static async getAgentDisbursements(matterId) {
        try {
            const response = await fetch(`${API_URL}/agent_disbursements/${matterId}`, {
                headers: await getAuthHeaders(),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching agent disbursements:', error);
        }
    }

    static async getGeneralAgentDisbursements() {
        try {
            const response = await fetch(`${API_URL}/agent_disbursements`, {
                headers: await getAuthHeaders(),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error fetching agent disbursements:', error);
        }
    }

    static async addAgentDisbursement(formData) {
        try {
            const response = await fetch(`${API_URL}/agent_disbursements`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...(await getAuthHeaders()),
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log('Error adding agent disbursement:', error);
        }
    }
   
}

export default FinancialService;
