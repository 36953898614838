import React, { useState } from 'react';
import './ExportTasks.css';
import ReportingService from '../../../../../AppSettings/services/ReportingService';
import { Auth } from 'aws-amplify';


const columnOptions = [
    'Task ID', 'Matter ID', 'Task Title', 'Description', 'Due Date', 'Assigned To',
    'Status', 'Created By', 'Task Type'
];

const ExportTasks = () => {
    const [selectedColumns, setSelectedColumns] = useState(columnOptions);
    const [reportType, setReportType] = useState('all');
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleColumnChange = (column) => {
        const newSelection = selectedColumns.includes(column)
            ? selectedColumns.filter(c => c !== column)
            : [...selectedColumns, column];
        setSelectedColumns(newSelection);
    };

    const toggleAllColumns = () => {
        if (selectedColumns.length === columnOptions.length) {
            setSelectedColumns([]);
        } else {
            setSelectedColumns(columnOptions);
        }
    };

    const downloadReport = async () => {
        try {
            let response;
            if (reportType === 'all') {
                response = await ReportingService.getAllTasks(selectedColumns);
            } else {
                const user = await Auth.currentAuthenticatedUser();
                const cognitoSub = user.attributes.sub;
                response = await ReportingService.getTasksByEmployeeId(cognitoSub, selectedColumns);
            }
    
            if (response.status !== 200) {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }
    
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = reportType === 'all' ? "Tasks Report.xlsx" : "My Tasks Report.xlsx";
            document.body.appendChild(a);
            a.click();
    
            // Use a timeout before revoking the URL to prevent immediate revocation
            setTimeout(() => {
                window.URL.revokeObjectURL(downloadUrl);
                a.remove();
            }, 100); // Adjust timing as needed
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };
    

    const closeModal = () => setIsModalOpen(false);

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('reporting-overlay')) {
            closeModal();
        }
    };

    return (
        isModalOpen && (
            <div className="reporting-overlay" onClick={handleOverlayClick}>
                <div className="reporting-modal-content">
                    
                    <div className="reporting-button-container">
                        <button onClick={downloadReport} className="Reporting-Download-button">Download Report</button>
                        <button onClick={toggleAllColumns} className="Reporting-Export-button">
                            {selectedColumns.length === columnOptions.length ? 'Deselect All' : 'Select All'}
                        </button>
                    </div>
                    <div className="report-type-toggle">
                        <label>
                            <input
                                type="radio"
                                name="reportType"
                                value="all"
                                checked={reportType === 'all'}
                                onChange={() => setReportType('all')}
                            />
                            All Tasks
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="reportType"
                                value="my"
                                checked={reportType === 'my'}
                                onChange={() => setReportType('my')}
                            />
                            My Tasks
                        </label>
                    </div>
                    {columnOptions.map((column, idx) => (
                        <label key={idx} className="reporting-checkbox">
                            <input
                                type="checkbox"
                                checked={selectedColumns.includes(column)}
                                onChange={() => handleColumnChange(column)}
                                className="reporting-visually-hidden"
                            />
                            <span className="reporting-label">{column}</span>
                        </label>
                    ))}
                </div>
            </div>
        )
    );
};

export default ExportTasks;
