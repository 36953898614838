import React, { useState } from 'react';
import "./ChecklistTable.css";
import ChecklistSideBar from './ChecklistSideBar/ChecklistSideBar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ChecklistTable = ({ data }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);

  const openSidebar = (checklist) => {
    setSelectedChecklist(checklist);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedChecklist(null);
  };


  const truncateTitle = (title) => {
    if (!title || typeof title !== 'string') {
      return ""; // Return an empty string if title is invalid.
    }
    const words = title.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + " ..."; // Truncate to 20 words.
    }
    return title
  };
  

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Description</th>
            <th className="due-date-header">
              <span>Matter ID</span>
              <Link to="/createChecklist" className="add-icon-link">
                <FontAwesomeIcon icon={faPlus} className="icon-button" aria-label="Add" />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
               <td>{row.status}</td>
             <td>
                <button
                  className="TaskLink"
                  title={row.description}
                  onClick={() => openSidebar(row)}
                >
                  {truncateTitle(row.description)}
                </button>
              </td>
              <td>{row.matterid}</td>

            </tr>
          ))}
        </tbody>
      </table>
      <ChecklistSideBar checklist={selectedChecklist} onClose={closeSidebar} isOpen={isSidebarOpen} />
    </div>
  );
};

export default ChecklistTable;
