import React, { useEffect, useState } from 'react';
import './InProgress.css';
import TaskService from '../../../../../../../../AppSettings/services/TaskService';
import { useParams } from "react-router-dom";

const InProgress = () => {
    const [inProgressCount, setInProgressCount] = useState(0); // State to manage the in-progress count
    const { matterId } = useParams();

    useEffect(() => {
        const fetchInProgressTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistributionByMatterId(matterId);
                
                // Find the 'In Progress' task and get its count
                const inProgressTask = data.find(task => task.status === 'In Progress');
                const count = inProgressTask ? inProgressTask.count : 0;

                setInProgressCount(count); // Update the state with in-progress task count
            } catch (error) {
                console.error('Error fetching in-progress tasks:', error);
            }
        };

        fetchInProgressTasks(); // Fetch the data when the component mounts
    }, [matterId]);

    return (
        <div className="inprogress-container">
            <span className="inprogress-count">{inProgressCount}</span>
            <span className="inprogress-label">In-Progress</span>
        </div>
    );
};

export default InProgress;
