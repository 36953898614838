import { Line } from "react-chartjs-2";
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Ensure you're importing Chart.js
import "./FinancialGraph.css";

const FinancialGraph = ({ lineData, lineOptions }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = chartRef.current;

    // Cleanup function to destroy the chart
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [lineData, lineOptions]); // Reinitialize only when data/options change

  return (
    <div className="card financial-overview">
      <h5 className="financial-dashboard-head">Financial Overview</h5>
      <Line ref={chartRef} data={lineData} options={lineOptions} />
    </div>
  );
};

export default FinancialGraph;
