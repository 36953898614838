import "./FixedFees.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect} from "react";
import FinancialService from "../../../../AppSettings/services/FinancialService";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import AddFixedFeeModal from "./Components/AddFixedFeeModal";

const FixedFees = () => {
  const { matterId } = useParams();
  const [pageLimit, setPageLimit] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['assignedFixedFees', matterId], // Unique query key
    queryFn: () => FinancialService.getAssignedFixedFees(matterId), // Fetch function
    enabled: !!matterId, // Only run if matterId is defined
  });

  useEffect(() => {
    if (data) {
      setPageLimit(Math.max(1, Math.ceil(data.length / 5)));
    }
  }, [data]);

  const [currPage, setCurrPage] = useState(1);

  const handlePageDecrement = () => {
    if (currPage > 1) {
      // Probably do something on matters page as a result of page state changing
      setCurrPage(currPage - 1);
    }
  };

  const handlePageIncrement = () => {
    if (currPage < pageLimit) {
      // Probably do something on matters page as a result of page state changing
      setCurrPage(currPage + 1);
    }
  };

  const handleAddSuccess = () => {
    refetch(); // Refetch data after adding a new fee
  };

  if (isLoading) {
    return <div>Loading fixed fees...</div>;
  }

  if (error) {
    return <div>Error fetching fixed fees</div>;
  }

  return (
    <div className="fixedFees-container">
      <div className="fixedFees-header">
        <h1>Fixed Fees</h1>
        <button className="MF-fixedFees-add-button" onClick={() => setShowModal(true)}>+</button>
      </div>
      <div className="fee-table-container">
        <table className="fee-table">
          <thead>
            <tr>
              <th>Fee Code</th>
              <th>Narrative</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>
                  <button className="fixed-fee-code">{row.fee_code}</button>
                </td>
                <td>{row.narrative}</td>
                <td>{row.fees}</td>
                <td>{row.date_added}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fee-footer">
        <div>
          <button
            className="fee-pagination-button"
            onClick={handlePageDecrement}
          >
            <FontAwesomeIcon
              icon={faAnglesLeft}
              className="fee-pagination-button"
            />
          </button>
          <span>
            {currPage} of {pageLimit}
          </span>
          <button
            className="fee-pagination-button"
            onClick={handlePageIncrement}
          >
            <FontAwesomeIcon
              icon={faAnglesRight}
              className="fee-pagination-button"
            />
          </button>
        </div>
      </div>
      {showModal && (
        <AddFixedFeeModal onClose={() => setShowModal(false)} onAddSuccess={handleAddSuccess} />
      )}
    </div>
  );
};

export default FixedFees;
