import FileButton from "./Components/FileButton/FileButton";
import FileTable from "./Components/FileTable/FileTable";
import FilesTopBar from "./Components/TopBar/TopBar";
import "./Files.css";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import FileList from "./file-list";

const filesData = [
  {
    id: 1,
    title: "Templates",
    date: "09/24/2024",
    amount: Math.floor(Math.random() * 100),
  }
];

const innerFileData = [
  {
    name: "NDA #1",
    type: "Open",
    edit: "Sashwat",
    date: "October 12th 2024",
  }
];

const Files = () => {
  const { matterId } = useParams();
  const [expandedFile, setExpandedFile] = useState(null);
  const [filteredFilesData, setFilteredFilesData] = useState(filesData);

  const handleSearch = (search) => {
    const filtered = filteredFilesData.filter((entry) =>
      entry.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredFilesData(filtered);
  };

  const handleClick = (id) => {
    setExpandedFile(id === expandedFile ? null : id); // Toggle file details
  };

  return (
    <div className="filesPage">
      <FilesTopBar onSearch={handleSearch}></FilesTopBar>
      {/* <div className="filesList">
        {filteredFilesData.map((file) => (
          <FileButton
            key={file.id}
            file={file}
            handleClick={handleClick}
            expandedFile={expandedFile}
          ></FileButton>
        ))}
      </div> */}
      {/* <div>
        {expandedFile ? (
          <div className="overlay">
            <div className="overlay-content">
              <button
                className="close-btn"
                onClick={() => {
                  setExpandedFile(null);
                }}
              >
                X
              </button>
              <div className="file-list">
                <FileTable data={innerFileData}></FileTable>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {expandedFile ? <FileTable data={innerFileData}></FileTable> : <></>}
        Current selected file id: {expandedFile}
      </div> */}
      <div>
        <FileList matterId={matterId}/>
      </div>
    </div>
  );
};

export default Files;