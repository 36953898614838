import React from 'react';
import './TaskSideBar.css';

const TaskSideBar = ({ task, onClose, isOpen }) => {
  if (!task) return null; // Avoid rendering if no task is selected

  return (
    <div className={`Task-sidebar ${isOpen ? 'open' : ''}`}>
    <div className="Task-header">
      <h2>Task Details</h2>
      <button className="Task-close-btn" onClick={onClose}>✕</button>
    </div>
  
   <div className="Task-Sidebar-contents">
    <div className="Task-details-container">
      <div className="Task-title">
        <h3>{task.task_title}</h3>
      </div>
      
      <div className="Task-meta">
        <p><strong>Due</strong> - <span>{task.duedate}</span></p>
        <p><strong>Assigned To</strong> - <span>{task.assignedto}</span></p>
      </div>
    </div>

      <div className="Task-progress-bar-container">
        <div className="Task-progress-bar">
          <div className="Task-progress-fill"></div>
        </div>
      </div>

    <div className="Task-info">
        <div className="Task-info-row">
            <p><strong>Status -</strong> {task.status}</p>
            <p><strong>ID -</strong> {task.taskid}</p>
        </div>
        <p><strong>Description -</strong> {task.description}</p>
    </div>

      <div className="Task-associated-documents">
        <h4>Associated Documents</h4>
        {/* Add document-related content here */}
      </div>
      </div>
    </div>
  );
};

export default TaskSideBar;
