import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import TopBar from "./Components/TopBar/TopBar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faUsers,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ClientService from "../../AppSettings/services/ClientService";
import EmployeeService from "../../AppSettings/services/EmployeeService";
import "./CreateMattersPage.css";

const CreateMattersPage = () => {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);
  const [error, setError] = useState("");

  const clientData = useQuery({
    queryKey: ["Clients"],
    queryFn: ClientService.getClients,
  });

  const employeeData = useQuery({
    queryKey: ["Employee"],
    queryFn: EmployeeService.getEmployees,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientData, employeeData] = await Promise.all([
          ClientService.getClients(),
          EmployeeService.getEmployees(),
        ]);

        setClients(
          clientData.map((client) => ({
            value: client.ClientID,
            label: `${client.ClientID} - ${client.FirstName} (${client.Company})`,
          }))
        );

        setEmployees(
          employeeData.map((employee) => ({
            value: employee.EmployeeID,
            label: `${employee.FirstName} ${employee.LastName}`,
          }))
        );
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load clients or employees.");
      } finally {
        setLoadingClients(false);
      }
    };

    fetchData();
  }, []);

  // State for form inputs
  const [formData, setFormData] = useState({
    mattername: "",
    mattertype: "",
    clientid: "",
    employeeid: "",
    opendate: "",
    closedate: "",
    description: "",
    amount: "0",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Universal function to handle select changes
  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : "",
    }));
  };

  return (
    <div className="mattersPage">
      <TopBar formData={formData} />
      <div className="Matter-entire-form-container">
        <div className="Matter-form-container">
          {/* Matter Name */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Matter Name</label>
            <div className="Matter-input-icon">
              <input
                type="text"
                name="mattername"
                placeholder="Type in name"
                value={formData.mattername}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Matter Type */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Matter Type</label>
            <div className="Matter-input-icon">
              <input
                type="text"
                name="mattertype"
                placeholder="Type or search type"
                value={formData.mattertype}
                onChange={handleInputChange}
              />
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>

          {/* Client Dropdown */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Client</label>
            <Select
              options={clients}
              onChange={handleSelectChange("clientid")}
              placeholder="Select or search a client..."
              isClearable
              isLoading={loadingClients}
            />
          </div>

          {/* Assign Leader Dropdown */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Assign Leader</label>
            <Select
              options={employees}
              onChange={handleSelectChange("employeeid")}
              placeholder="Select or search an employee..."
              isClearable
            />
          </div>

          {/* Date Inputs */}
          <div className="Matter-date-group">
            <div className="Matter-item1">
              <label className="Matter-form-group-label">Creation Date</label>
              <div className="Matter-input-icon">
                <input
                  type="date"
                  name="opendate"
                  value={formData.opendate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Matter-item2">
              <label className="Matter-form-group-label">Due Date</label>
              <div className="Matter-input-icon">
                <input
                  type="date"
                  name="closedate"
                  value={formData.closedate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Description</label>
            <textarea
              className="matter-textarea"
              name="description"
              placeholder="Type"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Side Buttons */}
        <div className="Matter-side-buttons">
          <button className="Matter-side-button side1">
            Add Team Members <FontAwesomeIcon icon={faUsers} />
          </button>
          <button className="Matter-side-button side2">
            Add Invoices <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="Matter-side-button side3">
            More Information <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button className="Matter-side-button side4">
            Add Financial Data <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="Matter-side-button side5">
            Create Sub Matter <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button className="Matter-side-button side6">
            Add Documents <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateMattersPage;
