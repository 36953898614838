import React, { useState } from 'react';
import './TasksTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import TaskSideBar from './TaskSideBar/TaskSideBar';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';

const TasksTable = ({ data }) => {
  const { matterId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const openSidebar = (task) => {
    setSelectedTask(task);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedTask(null);
  };

  const truncateTitle = (title) => {
    if (!title || typeof title !== 'string') return "";
    const words = title.split(" ");
    return words.length > 9 ? words.slice(0, 9).join(" ") + " ..." : title;
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Task</th>
            <th>Status</th>
            <th>Type</th>
            <th>Assigned To</th>
            <th className="due-date-header">
              <span>Due Date</span>
              <Link to={`/matterDetails/${matterId}/tasks/createTasks`} className="add-icon-link">
              <FontAwesomeIcon icon={faPlus} className="icon-button" aria-label="Add" />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                <button
                  className="TaskLink"
                  title={row.task_title}
                  onClick={() => openSidebar(row)}
                >
                  {truncateTitle(row.task_title)}
                </button>
              </td>
              <td>{row.status}</td>
              <td>{row.task_type}</td>
              <td>
                <div className="d-flex">
                  <FontAwesomeIcon icon={faUser} className="tableIcon" />
                  <span>{row.assignedto}</span>
                </div>
              </td>
              <td>{row.duedate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Render Sidebar with slide-in effect */}
      <TaskSideBar task={selectedTask} onClose={closeSidebar} isOpen={isSidebarOpen} />
    </div>
  );
};

export default TasksTable;
