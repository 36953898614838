import React, { useEffect, useState } from 'react';
import './Pending.css';
import TaskService from '../../../../../../AppSettings/services/TaskService';

const Pending = () => {
    const [pendingCount, setPendingCount] = useState(0);

    useEffect(() => {
        const fetchPendingTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistribution();

                // Find the task with status 'Pending' and get its count
                const pendingTasks = data.find(task => task.status === 'Pending');
                const count = pendingTasks ? pendingTasks.count : 0;

                setPendingCount(count);
            } catch (error) {
                console.error('Error fetching pending tasks:', error);
            }
        };

        fetchPendingTasks();
    }, []);

    return (
        <div className="task-pending-container">
            <span className="task-pending-input">{pendingCount}</span>
            <span className="task-pending-label">Pending</span>
        </div>
    );
};

export default Pending;
