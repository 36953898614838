import React from 'react';
import { useState, useEffect } from "react";
import './ClientInfo.css';
import { useParams } from 'react-router-dom';
import MatterService from '../../../../../AppSettings/services/MatterService';
import { useQuery } from "@tanstack/react-query";

const ClientInfo = () => {
    const { matterId } = useParams();
    const [matter, setMatter] = useState(null);

    const { data, isPending, error } = useQuery({
        queryKey: ["Matters", matterId],  // Include matterId in the queryKey
        queryFn: () => MatterService.getMatterDetails(matterId), // Call the service with matterId
        enabled: !!matterId // Run the query only if matterId is defined
    });
    
    useEffect(() => {
        if (data) {
          setMatter(data);
        }
    }, [data])

    if (isPending) {
        return <span>Loading...</span>;
    }

    if (error) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <div className="client-card">
            <div className="client-title">Client</div>
            <div className="client-details">
                <div className="client-name">{matter?.client_name}</div>
                <div className="client-contact">
                    <div className="client-phone">
                        <i className="fas fa-phone"></i>{matter?.client_phone} 
                    </div>
                    <div className="client-email">
                        <i className="fas fa-envelope"></i> {matter?.client_email} 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientInfo;
