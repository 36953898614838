import React, { useEffect, useState } from 'react';
import './Pending.css';
import TaskService from '../../../../../../../../AppSettings/services/TaskService';
import { useParams } from "react-router-dom";

const Pending = () => {
    const [pendingCount, setPendingCount] = useState(0); // State to manage the pending task count
    const { matterId } = useParams();

    useEffect(() => {
        const fetchPendingTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistributionByMatterId(matterId);
                
                // Find the 'Pending' task and get its count
                const pendingTask = data.find(task => task.status === 'Pending');
                const count = pendingTask ? pendingTask.count : 0;

                setPendingCount(count); // Update the state with pending task count
            } catch (error) {
                console.error('Error fetching pending tasks:', error);
            }
        };

        fetchPendingTasks(); // Fetch the data when the component mounts
    }, [matterId]);

    return (
        <div className="task-pending-container">
            <span className="task-pending-input">{pendingCount}</span>
            <span className="task-pending-label">Pending</span>
        </div>
    );
};

export default Pending;
