import React, { useState, useEffect, useRef } from 'react';
import TimerPopout from './TimerPopout'; // Import TimerPopout component
import './Timer.css';
import { FaPlay, FaPause, FaRedo, FaUpload } from 'react-icons/fa';

function Timer() {
    const [timeLeft, setTimeLeft] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [showPopout, setShowPopout] = useState(false); // State to control TimerPopout visibility
    const [timerTime, setTimerTime] = useState(''); // State to hold formatted time for popout
    const timerRef = useRef(null);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const handleStartPause = () => {
        if (isRunning) {
            clearInterval(timerRef.current);
        } else {
            timerRef.current = setInterval(() => {
                setTimeLeft((prev) => prev + 1);
            }, 1000);
        }
        setIsRunning(!isRunning);
    };

    const handleReset = () => {
        clearInterval(timerRef.current);
        setTimeLeft(0);
        setIsRunning(false);
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleUpload = () => {
        if (!showPopout) {
            setTimerTime(formatTime(timeLeft)); // Set the current time
            setShowPopout(true); // Show the popout with the recorded time
        }
    };
    

    useEffect(() => {
        return () => clearInterval(timerRef.current);
    }, []);

    if (!isVisible) return null;

    if (isMinimized) {
        return (
            <div className="timer-minimized" onClick={handleMinimize}>
                {formatTime(timeLeft)}
            </div>
        );
    }

    return (
        <div className="timer-container">
            <div className="timer-header">
                <button className="timer-minimize-button" onClick={handleMinimize}>−</button>
                <button className="timer-close-button" onClick={handleClose}>×</button>
            </div>
            <div className="timer-progress-ring">
                <div className="time-display">{formatTime(timeLeft)}</div>
            </div>
            <div className="timer-controls">
                <button onClick={handleStartPause} className="timer-control-button">
                    {isRunning ? <FaPause /> : <FaPlay />}
                </button>
                <button onClick={handleReset} className="timer-control-button">
                    <FaRedo />
                </button>
                <button onClick={handleUpload} className="timer-control-button">
                    <FaUpload />
                </button>
            </div>


            {showPopout && <TimerPopout timer={timerTime} onClose={() => setShowPopout(false)} />}
            </div>
    );
}

export default Timer;
