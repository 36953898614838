import React, { useEffect, useState } from 'react';
import './Completed.css';
import TaskService from '../../../../../../AppSettings/services/TaskService';

const Completed = () => {
    const [completedCount, setCompletedCount] = useState(0);

    useEffect(() => {
        const fetchCompletedTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistribution();

                // Find the task with status 'Completed' and get its count
                const completedTask = data.find(task => task.status === 'Completed');
                const count = completedTask ? completedTask.count : 0;

                setCompletedCount(count);
            } catch (error) {
                console.error('Error fetching completed tasks:', error);
            }
        };

        fetchCompletedTasks();
    }, []);

    return (
        <div className="Completed-container">
            <span className="Completed-count">{completedCount}</span>
            <span className="Completed-label">Completed</span>
        </div>
    );
};

export default Completed;
