import { useEffect, useState } from "react";
import "./AgentsPage.css";
import AgentsTopBar from "./Components/TopBar/TopBar.js";
import AgentsTable from "./Components/AgentsTable/AgentsTable.js";
import AgentsPagination from "./Components/Pagination/Pagination.js";
import AgentsService from "../AppSettings/services/AgentsService.js";

const PAGELIMIT = 12;

const columns = {
  "Agent ID": "AgentID",
  Agents: "",
  Company: "Company",
  Email: "Email",
  Phone: "Phone",
  Address: "Address"
};

const AgentsPage = () => {
  const [agents, setAgents] = useState([]); // Store fetched agents
  const [filteredAgents, setFilteredAgents] = useState([]); // Filtered agents for display
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch agents from the API
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const data = await AgentsService.getAgents();
        setAgents(data);
        setFilteredAgents(data); // Initialize filtered agents
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleSearch = (search, filter) => {
    if (!filter) {
      return;
    }

    let filtered;
    if (filter === "Agents") {
      filtered = agents.filter((entry) =>
        entry.FirstName.toLowerCase().includes(search.toLowerCase()) || entry.LastName.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      filtered = agents.filter((entry) =>
        entry[columns[filter]].toLowerCase().includes(search.toLowerCase())
      );
    }
    setFilteredAgents(filtered);
  };

  const handlePagination = (newPage) => {
    setPage(newPage);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="tasksPage">
      <AgentsTopBar onSearch={handleSearch} />
      <AgentsTable data={filteredAgents} />
      <AgentsPagination
        currPage={page}
        onPageChange={handlePagination}
        pageLimit={PAGELIMIT}
      />
    </div>
  );
};

export default AgentsPage;
