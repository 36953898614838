import React from 'react';
import './ChecklistSideBar.css';

const ChecklistSideBar = ({ checklist, onClose, isOpen }) => {
  if (!checklist) return null; // Avoid rendering if no checklist is selected

  return (
    <div className={`Checklist-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="Checklist-header">
        <h2>Checklist Details</h2>
        <button className="Checklist-close-btn" onClick={onClose}>✕</button>
      </div>
  
      <div className="Checklist-Sidebar-contents">
        <div className="Checklist-details-container">
          <div className="Checklist-title">
            <h3>{checklist.description}</h3>
          </div>
          
          <div className="Checklist-meta">
            <p><strong>Status</strong> - <span>{checklist.status}</span></p>
            <p><strong>Matter ID</strong> - <span>{checklist.matterid}</span></p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ChecklistSideBar;
