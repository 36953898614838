import React, { useEffect, useState } from 'react';
import './InProgress.css';
import TaskService from '../../../../../../AppSettings/services/TaskService';

const InProgress = () => {
    const [inProgressCount, setInProgressCount] = useState(0);

    useEffect(() => {
        const fetchInProgressTasks = async () => {
            try {
                const data = await TaskService.getTaskStatusDistribution();

                // Find the task with status 'In Progress' and get its count
                const inProgressTask = data.find(task => task.status === 'In Progress');
                const count = inProgressTask ? inProgressTask.count : 0;

                setInProgressCount(count);
            } catch (error) {
                console.error('Error fetching in-progress tasks:', error);
            }
        };

        fetchInProgressTasks();
    }, []);

    return (
        <div className="inprogress-container">
            <span className="inprogress-count">{inProgressCount}</span>
            <span className="inprogress-label">In-Progress</span>
        </div>
    );
};

export default InProgress;
