import React from 'react';
import './DeadlineCount.css';
import DeadlineGraph from './Components/DeadlineGraph/DeadlineGraph';
import DTCCount from './Components/DTCCount/DTCCount';
import RadarCount from './Components/RadarCount/RadarCount';

const DeadlineCount = () => {
    return (
        <div className="DC-container">
            {/* Header Section */}
            <div className="DC-header-container">
                <h1 className="DC-welcome-message">Welcome Sash</h1>
                <div className="DC-right-section">
                    <select className="DC-dropdown">
                        <option>Team</option>
                    </select>
                    <select className="DC-dropdown">
                        <option>Week</option>
                    </select>
                </div>
            </div>

            {/* Data Section */}
            <div className="DC-data-container">
                <div className="DC-count-container">     
                    <DTCCount />         
                    <RadarCount />
                </div>
                <div className="DC-DG-container">
                    <DeadlineGraph />
                </div>
            </div>
        </div>
    );
};

export default DeadlineCount;
