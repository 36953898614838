import React, { useEffect, useState } from 'react';
import './RecentNotifications.css';
import NotificationsService from '../../../AppSettings/services/NotificationService';

function RecentNotifications() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await NotificationsService.getNotifications();
        const fetchedData = response.data || []; // Default to empty array if undefined
        console.log('Fetched notifications:', fetchedData); // For debugging
    
        setNotifications(fetchedData.slice(0, 5)); // Safely slice the data
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]); // Set empty array on error
      }
    }
    
    fetchNotifications();
  }, []);

  return (
    <div className="notifications-container">
      <h2 className="notifications-title">Recent Notifications</h2>
      {notifications.map((notification, index) => (
        <div key={index} className="notification-item">
          <p className="notification-title">{notification.subject}</p>
          <div className="notification-meta">
            {notification.matterid && (
              <span className="notification-matter">{notification.matterid}</span>
            )}
            {notification.created_at && (
              <span className="notification-divider" />
            )}
            {notification.created_at && (
              <span className="notification-due">Created At: {notification.created_at}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecentNotifications;
