import React, { useEffect, useRef, useState } from 'react';
import './MatterStatus.css';
import { Chart } from 'chart.js/auto';
import MatterService from '../../../../AppSettings/services/MatterService';

function MatterStatus() {
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null); // Track the chart instance

    useEffect(() => {
        const fetchMatterStatusData = async () => {
            try {
                const data = await MatterService.getMatterStatusDistribution();

                // Extract labels and data values
                const labels = data.map(item => item.status);
                const values = data.map(item => item.count);

                // Create or update the chart with dynamic data
                if (chartInstance) {
                    chartInstance.data.labels = labels;
                    chartInstance.data.datasets[0].data = values;
                    chartInstance.update();
                } else {
                    const ctx = chartRef.current.getContext('2d');
                    const newChartInstance = new Chart(ctx, {
                        type: 'pie',
                        data: {
                            labels,
                            datasets: [
                                {
                                    data: values,
                                    backgroundColor: ['#4b72b0', '#6ba8ff', '#bfc7ff', '#9f76e5', '#ff78cb'],
                                    hoverOffset: 4,
                                    borderWidth: 0,
                                },
                            ],
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        },
                    });
                    setChartInstance(newChartInstance);
                }
            } catch (error) {
                console.error('Failed to fetch matter status distribution:', error);
            }
        };

        fetchMatterStatusData();
    }, [chartInstance]); // Ensure the effect runs when the chart instance changes

    return (
        <div className="matter-status-widget">
            <div className="matter-chart-container">
                <canvas className="matter-status-chart" ref={chartRef}></canvas>
            </div>
            <div className="matter-title">Status</div>
        </div>
    );
}

export default MatterStatus;
