// Calendar.js
import React, { useState } from 'react';
import './Calendar.css'; // Import the CSS for styling
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';

const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const renderHeader = () => {
        return (
            <div className="MD-calendar-header">
                <button className="MD-calendar-header-button" onClick={prevMonth}>
                    <FaChevronLeft />
                </button>
                <span className="MD-calendar-header-title">
                    {format(currentMonth, 'MMMM yyyy')}
                </span>
                <button className="MD-calendar-header-button" onClick={nextMonth}>
                    <FaChevronRight />
                </button>
            </div>
        );
    };

    const renderDays = () => {
        const days = [];
        const dateFormat = 'EEE'; // Short day name format (e.g., 'Mon')

        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="MD-calendar-day-name" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="MD-calendar-days-row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let days = [];
        let day = startDate;
        const dateFormat = 'd';

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(day, dateFormat);
                const cloneDay = day;

                days.push(
                    <div
                        className={`MD-calendar-cell ${
                            !isSameMonth(day, monthStart) ? 'disabled' : ''
                        } ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
                        key={day}
                        onClick={() => onDateClick(cloneDay)}
                    >
                        <span className="MD-calendar-number">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="MD-calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }

        return <div className="MD-calendar-body">{rows}</div>;
    };

    const onDateClick = (day) => {
        setSelectedDate(day);
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="MD-calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
        </div>
    );
};

export default Calendar;
