import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AgentService from "../../../AppSettings/services/AgentsService";
import { useMutation } from "@tanstack/react-query";

const CreateAgentsTopBar = ({ formData, setError, setFormData, setSuccess }) => {
  const mutation = useMutation({
    mutationFn: () => AgentService.addAgent(formData),
    onSuccess: (data) => {
      // Handle the successful mutation (e.g., show a success message or refetch data)
      console.log("Agent created successfully:", data);
      setFormData({
        FirstName: "",
        LastName: "",
        Company: "",
        Email: "",
        Phone: "",
        Address: "",
        Jurisdiction: ""
      });
      setError("");
      setSuccess("Successfully created new agent!");
      setTimeout(() => setSuccess(""), 5000);
    },
    onError: (error) => {
      // Handle the error (e.g., show an error message)
      console.error("Error creating agent:", error);
      setError(`Error: ${error.response.data.error}`);
    },
  })

  const handleSubmit = () => {
    console.log(formData);
    // Trigger the mutation with data
    mutation.mutate(formData);
  }

  return (
    <div className="createMattersTopBar">
      <div id="leftSideCreateMattersTopBar">
        <Link to="/agents">
          <button className="mattersBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="mattersBackButton" />
          </button>
        </Link>
        <span>Create an Agent</span>
      </div>
      <div id="rightSideCreateMattersTopBar">
        <button className="createMatterButton" onClick={handleSubmit}>Create Agent</button>
      </div>
    </div>
  );
};

export default CreateAgentsTopBar;
