import { useState, useEffect } from "react";
import "./NotificationsPage.css";
import NotificationsTopBar from "./Components/TopBar/TopBar.js";
import NotificationsTable from "./Components/NotificationsTable/NotificationsTable.js";
import NotificationsPagination from "./Components/Pagination/Pagination.js";
import NotificationsService from "../AppSettings/services/NotificationService.js";

const PAGELIMIT = 12; // Number of elements on each page

const columns = {
  Subject: "subject",
  Description: "description"
}

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]); // Store all notifications
  const [filteredNotifications, setFilteredNotifications] = useState([]); // Store filtered notifications
  const [paginatedNotifications, setPaginatedNotifications] = useState([]); // Store paginated data
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageLimit, setPageLimit] = useState(1);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setLoading(true);
        const response = await NotificationsService.getNotifications();
        setNotifications(response.data); // Set original data
        setPageLimit(Math.ceil(response.data.length / 12));
        setFilteredNotifications(response.data); // Set filtered data initially to all notifications
        updatePaginatedData(response.data, 1); // Initialize pagination
      } catch (err) {
        setError("An error occurred while fetching notifications.");
      } finally {
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  // Update paginated data based on current page and filtered notifications
  const updatePaginatedData = (data, page) => {
    const startIndex = (page - 1) * PAGELIMIT;
    const paginatedData = data.slice(startIndex, startIndex + PAGELIMIT);
    setPaginatedNotifications(paginatedData);
  };

  const handleSearch = (search, filter) => {
    if (!filter) {
      return;
    }
 
    const filtered = notifications.filter(entry => 
      entry[columns[filter]].toLowerCase().includes(search.toLowerCase())
    );
    setFilteredNotifications(filtered);
    updatePaginatedData(filtered, 1); // Reset to page 1 after search
    setPage(1);
    setPageLimit(Math.ceil(filtered.length / 12));
  };

  const handlePagination = (page) => {
    setPage(page);
    updatePaginatedData(filteredNotifications, page);
  };

  return (
    <div className="NotificationsPage">
      <NotificationsTopBar onSearch={handleSearch}></NotificationsTopBar>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <NotificationsTable data={paginatedNotifications}></NotificationsTable>
          <NotificationsPagination 
            currPage={page} 
            onPageChange={handlePagination} 
            pageLimit={pageLimit} 
            totalItems={filteredNotifications.length}
          ></NotificationsPagination>
        </>
      )}
    </div>
  );
};

export default NotificationsPage;
