import PieChart from '../Components/PieChart/PieChart';

function Disbursements({pieData}) {

    return (
        <PieChart title={"Disbursements"} pieData={pieData}></PieChart>
    );
}

export default Disbursements;
