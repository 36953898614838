import React, { useEffect, useState, useRef } from "react";
import { Client } from "@microsoft/microsoft-graph-client";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import * as msal from "@azure/msal-browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDownload, faTrashAlt, faArrowLeft, faFile, faFolderOpen, faExternalLinkAlt, faFolder } from '@fortawesome/free-solid-svg-icons';

import './Files.css';
import WebViewer from '@pdftron/webviewer';
import { saveAs } from 'file-saver';
import getAuthHeaders from "../../../AppSettings/routes/functions/getAuthHeaders";

const FileList = ({ matterId }) => {
  const viewer = useRef(null);
  const [webViewerInstance, setWebViewerInstance] = useState(null);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [siteId, setSiteId] = useState(process.env.REACT_APP_SHAREPOINT_SITE_ID);
  const [driveId, setDriveId] = useState(process.env.REACT_APP_SHAREPOINT_SITE_DRIVE_ID);
  const [folderUrl, setFolderUrl] = useState("");
  const [selectedFileKey, setSelectedFileKey] = useState(null); // will use for adobe pdfs
  const [folderHistory, setFolderHistory] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [clickedFile, setClickedFile] = useState(null);
  const [newFolderName, setNewFolderName] = useState(""); // For new folder creation
  const [newFileName, setNewFileName] = useState(""); // For new file creation
  const [newFileType, setNewFileType] = useState("docx"); // For selecting file type
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // "folder", "file", or "upload"
  const [templatesFolder, setTemplatesFolder] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      instance
        .acquireTokenSilent({
          scopes: ["Files.Read.All"],
          account: accounts[0], // Assuming there's only one account signed in
        })
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof msal.InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup({
                scopes: ["Files.Read.All"],
              })
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => {
                console.error("Error acquiring token:", error);
              });
          } else {
            console.error("Error acquiring token:", error);
          }
        });
    }
  }, [instance, accounts]);

  useEffect(() => {
    if (accessToken && matterId) {
      initializeMatterFolder();
    }
  }, [accessToken, matterId]);

  useEffect(() => {
    if (currentFolder) {
      fetchFolderContent(currentFolder);
    }
  }, [currentFolder]);

  const [viewerContainer, setViewerContainer] = useState(null);

  useEffect(() => {
    if (!webViewerInstance && viewerContainer) {
      const initializeWebViewer = async () => {
        const instance = await WebViewer(
          {
            path: '../../../lib',
            licenseKey: 'demo:1709879001877:7f2184640300000000567a2901e2a43ee3f7c5441b9cdb91949d35b6fa',
          },
          viewerContainer
        );
  
        setWebViewerInstance(instance);
      };
  
      initializeWebViewer();
    }
  }, [webViewerInstance, viewerContainer]);
  

  const fetchMatterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_data/${matterId}`, {
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.log('Error fetching matter details: ' + error.message);
      return null;
    }
  };


  const loadWebViewerDocument = async (documentURL, extension) => {
    if (!webViewerInstance) {
      console.error("WebViewer instance not available.");
      return;
    }
  
    const { documentViewer, annotationManager } = webViewerInstance.Core;
    webViewerInstance.UI.loadDocument(documentURL, {
      extension: extension,
    });
    documentViewer.addEventListener('documentLoaded', async () => {
      const doc = documentViewer.getDocument();
      const keys = await doc.getTemplateKeys();
      if (keys.length !== 0) {
        const matterDetails = await fetchMatterDetails();
        if (matterDetails) {
          await doc.applyTemplateValues(matterDetails);
          const data = await doc.getFileData({
            downloadType: 'templateFilledOffice',
            officeOptions: {
              doTemplatePrep: true,
            },
          });
          const arr = new Uint8Array(data);
          const blob = new Blob([arr], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const fileName = 'template-filled.docx';
          const uploadUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${currentFolder.id}:/${fileName}:/content`;
  
          try {
            const response = await fetch(uploadUrl, {
              method: 'PUT',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              },
              body: blob,
            });
  
            if (response.ok) {
              console.log('File uploaded successfully');
            } else {
              console.error('Error uploading file:', response.statusText);
            }
          } catch (error) {
            console.error('Error uploading file:', error);
          }
        }
      }
    });
  };
  

  const fetchOneDriveFiles = async () => {
    let graphURL = '';
    if (process.env.REACT_APP_FILE_SYSTEM == 'SHAREPOINT') {
      graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`;
    } else if (process.env.REACT_APP_FILE_SYSTEM == 'ONEDRIVE') {
      graphURL = 'https://graph.microsoft.com/v1.0/me/drive/root:/SEPT:/children';
    }
    try {
      let response = await fetch(
        graphURL,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let data = await response.json();
      let matterFolder = data.value.find(item => item.folder && item.name === matterId);

      if (matterFolder) {
        setFolderUrl(matterFolder.webUrl);
        setCurrentFolder(matterFolder);
      } else {
        matterFolder = await createFolder(matterId);
        setFolderUrl(matterFolder.webUrl);
        setCurrentFolder(matterFolder);
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  
  const initializeMatterFolder = async () => {
    if (!accessToken || !matterId) {
      console.log("Access token or matter ID not available.");
      return;
    }
  
    try {
      const graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`;
      let response = await fetch(graphURL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) throw new Error('Failed to fetch folder details');
  
      let data = await response.json();
      let matterFolder = data.value.find(item => item.folder && item.name === matterId);
  
      // Fetch or Create Matter Folder
      if (!matterFolder) {
        console.log(`No folder found for matterId: ${matterId}, creating new folder.`);
        matterFolder = await createFolder(matterId);
      }
  
      // Set Matter Folder
      if (matterFolder) {
        setFolderUrl(matterFolder.webUrl);
        setCurrentFolder(matterFolder);
        console.log(`Folder set for matterId: ${matterId}`);
      }
  
      // Fetch or Create Templates Folder
      let templatesFolder = data.value.find(item => item.folder && item.name === "Templates");
      if (!templatesFolder) {
        console.log("No Templates folder found, creating new folder.");
        templatesFolder = await createFolder("Templates");
      }
  
      if (templatesFolder) {
        setTemplatesFolder(templatesFolder); // Store the Templates folder in state
        setFolders((prevFolders) => [...prevFolders, templatesFolder]); 
        console.log("Templates folder set successfully.");
      }
  
    } catch (error) {
      console.error("Error initializing matter folder:", error);
    }
  };
    
const createSubFolder = async (folderName, parentFolderId) => {
  if (!parentFolderId) {
    console.error("Parent folder ID is required to create a subfolder.");
    return null;
  }

  // Construct the API endpoint to create a folder inside the specified parent folder.
  const graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${parentFolderId}/children`;

  try {
    const response = await fetch(graphURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: folderName,
        folder: {},
        "@microsoft.graph.conflictBehavior": "rename" // Handle conflicts by renaming the new folder
      })
    });

    if (!response.ok) {
      console.error("Failed to create subfolder:", response.statusText);
      return null;
    }

    const folder = await response.json();
    return folder;
  } catch (error) {
    console.error("Error creating subfolder:", error);
    return null;
  }
};

  const createFolder = async (folderName) => {
    const graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`;
  
    try {
      const response = await fetch(
        graphURL,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: folderName,
            folder: {},
            "@microsoft.graph.conflictBehavior": "rename" // Handle conflicts by renaming the new folder
          })
        }
      );
  
      if (!response.ok) {
        console.error("Failed to create folder:", response.statusText);
        return null;
      }
  
      const folder = await response.json();
      return folder;
    } catch (error) {
      console.error("Error creating folder:", error);
      return null;
    }
  };
  

  const handleFileClick = (file) => {
    if (!webViewerInstance) {
      console.error("WebViewer instance not initialized.");
      return;
    }
  
    loadWebViewerDocument(file['@microsoft.graph.downloadUrl'], file.name.split('.').pop());
  };

  const handleOpenInNewTab = (file, event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the row click handler
    window.open(file.webUrl, '_blank');
  };

  const fetchFolderContent = async (folder) => {
    let graphURL = '';
    if (process.env.REACT_APP_FILE_SYSTEM == 'SHAREPOINT') {
      graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${folder.id}/children`;
    } else if (process.env.REACT_APP_FILE_SYSTEM == 'ONEDRIVE') {
      graphURL = `https://graph.microsoft.com/v1.0/me/drive/items/${folder.id}/children`;
    }
  
    try {
      const response = await fetch(graphURL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      if (!response.ok) throw new Error("Failed to fetch folder content");
  
      const data = await response.json();
  
      // Separate files and folders
      const newFolders = data.value.filter(item => item.folder);
      const newFiles = data.value.filter(item => item.file);
  
      // Check if the current folder is the root of the matterId folder
      if (currentFolder && currentFolder.name === matterId) {
        // Check if the Templates folder is already present in state
        if (templatesFolder && !newFolders.some(folder => folder.id === templatesFolder.id)) {
          newFolders.unshift(templatesFolder);
        }
      }
  
      setFolders(newFolders);
      setFiles(newFiles);
    } catch (error) {
      console.error("Error fetching folder content:", error);
    }
  };
  
  const handleFolderClick = async (folder) => {
    setFolderHistory((prevHistory) => [...prevHistory, currentFolder]);
    setCurrentFolder(folder);
  };

  const separateAndAssignItemsOnLoad = async (itemList) => {
    setFolders([]);
    setFiles([]);
    itemList?.value?.forEach((item) => {
      if (item.folder && !folders.some((folder) => folder.id === item.id)) {
        setFolders((prevFolders) => [...prevFolders, item]);
      } else if (item.file && !files.some((file) => file.id === item.id)) {
        setFiles((prevFiles) => [...prevFiles, item]);
      }
    });
  };

  const handleBackButtonClick = async () => {
    if (folderHistory.length > 0) {
      const previousFolder = folderHistory.pop();
      setCurrentFolder(previousFolder);
    }
  };

  
  const handleCreateSubFolder = async () => {
    if (newFolderName.trim() !== "" && currentFolder) {
      const newSubFolder = await createSubFolder(newFolderName, currentFolder.id);
      if (newSubFolder) {
        console.log('Subfolder created successfully:', newSubFolder.name);
        setFolders(prevFolders => [...prevFolders, newSubFolder]);
        fetchFolderContent(currentFolder); // Refresh the folder list
        setNewFolderName(""); // Clear the input field after successful creation
      }
    } else {
      console.error("No current folder selected or folder name is empty.");
    }
  };
  


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && currentFolder) {
      const uploadUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${currentFolder.id}:/${file.name}:/content`;

      try {
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': file.type,
          },
          body: file
        });

        if (response.ok) {
          console.log('File uploaded successfully');
          fetchFolderContent(currentFolder); // Refresh the folder content
        } else {
          console.error('Error uploading file:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleCreateFile = async () => {
    if (newFileName.trim() !== "") {
      let fileContent;
      let contentType;
      let fileExtension;

      switch (newFileType) {
        case "docx":
          fileContent = new Blob([""], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          fileExtension = ".docx";
          break;
        case "xlsx":
          fileContent = new Blob([""], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          fileExtension = ".xlsx";
          break;
        case "pptx":
          fileContent = new Blob([""], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
          contentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          fileExtension = ".pptx";
          break;
        case "txt":
          fileContent = new Blob([""], { type: 'text/plain' });
          contentType = 'text/plain';
          fileExtension = ".txt";
          break;
        default:
          console.error("Unsupported file type");
          return;
      }

      const uploadUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${currentFolder.id}:/${newFileName}${fileExtension}:/content`;

      try {
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': contentType,
          },
          body: fileContent
        });

        if (response.ok) {
          console.log('File created successfully');
          fetchFolderContent(currentFolder); // Refresh the folder content
          setNewFileName(""); // Clear the input field
        } else {
          console.error('Error creating file:', response.statusText);
        }
      } catch (error) {
        console.error('Error creating file:', error);
      }
    }
  };

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewFolderName("");
    setNewFileName("");
  };

  const handleModalSubmit = () => {
    if (modalType === "folder") {
      handleCreateSubFolder();
    } else if (modalType === "file") {
      handleCreateFile();
    }
    handleCloseModal();
  };

  return (
    <div>
      <div className="Tab-Container" style={{ display: 'flex' }}>
        <div className="File-files-sidebar">
          <div className="File-topbar-Create-Dropdown-Container">
         
            <div className="File-file-nav-buttons">
              {folderHistory.length > 0 && (
                <button className="create-button" onClick={handleBackButtonClick}>
                  <FontAwesomeIcon icon={faArrowLeft} className="icon-circle" />
                </button>
              )}
              <div className="File-dropdown">
                <button className="create-button">
                  <FontAwesomeIcon icon={faPlus} className="icon-circle" /> Add
                </button>
                <div className="File-dropdown-content">
                  <button onClick={() => handleShowModal("folder")}>Create Folder</button>
                  <button onClick={() => handleShowModal("file")}>Create File</button>
                  <button onClick={() => document.getElementById('file-upload').click()}>
                    Upload File
                  </button>
                  <input type="file" id="file-upload" onChange={handleFileUpload} style={{ display: 'none' }} />
                </div>
              </div>
              {folderUrl && (
                <button className="File-top-bar-btn" 
                  onClick={() => window.open(folderUrl, '_blank')}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} className="TB-icon-color"/> 
                </button>
              )}
            </div>
          </div>
          <div className="File-files-Subdirectory-section">
            <ul>
              {folders.map((folder) => (
                <div key={folder.id}>
                  <li onClick={() => handleFolderClick(folder)} className="File-folder-item" style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faFolder}/> {folder.name} 
                  </li>
                </div>
              ))}
            </ul>
            <ul>
              {files.map((file) => (
                <div key={file.id}>
                  <li>
                    <button className="File-file-button" onClick={() => handleFileClick(file)}>
                    <div className="File-file-info">
                      <FontAwesomeIcon icon={faFile} /> {file.name}
                    </div>
                      <div className="File-file-actions">
                        <FontAwesomeIcon icon={faExternalLinkAlt} onClick={(e) => handleOpenInNewTab(file, e)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                      </div>
                    </button>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
    
        <div className="File-file-preview-area">
  <div
    className="MyComponent"
    ref={(el) => {
      if (el && !viewerContainer) setViewerContainer(el);
    }}
    style={{ height: "100vh" }}
  ></div>
</div>

      {showModal && (
        <div id="myModal" className="File-modal" style={{ display: 'block' }}>
          <div className="File-modal-content">
            <span className="File-close" onClick={handleCloseModal}>&times;</span>
            <h2>{modalType === "folder" ? "Create New Folder" : "Create New File"}</h2>
            {modalType === "folder" && (
              <div>
                <label>Folder Name</label>
                <input type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
              </div>
            )}
            {modalType === "file" && (
              <div>
                <label>File Name</label>
                <input type="text" value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
                <label>File Type</label>
                <select value={newFileType} onChange={(e) => setNewFileType(e.target.value)}>
                  <option value="docx">Word Document (.docx)</option>
                  <option value="xlsx">Excel Spreadsheet (.xlsx)</option>
                  <option value="pptx">PowerPoint Presentation (.pptx)</option>
                  <option value="txt">Text File (.txt)</option>
                </select>
              </div>
            )}
            <button onClick={handleModalSubmit}>Create</button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default FileList;
